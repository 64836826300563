import React from "react";
import { Link } from "react-router-dom";
import {
  Container
} from "reactstrap";

const LykeHeader = () => {
  return (
    <>
             
        <div className="page-header header-filter" style={{
              backgroundImage:
                "url(" + require("assets/img/sensolykemain.jpg") + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: '100vh'
            }} >

            <Container>
                <span className="headerTitle">Senso<b>LYKE</b></span>
                <div class="sns">
    {/* <ul>
      <li> <Link to="/o-nas"><a>POZNAJ</a></Link></li>
      <li><a href="http://sklep.sensolyke.pl">KUPUJ</a></li>
    </ul> */}
  </div>
            </Container>
        </div>    </>
  );
};

export default LykeHeader;
