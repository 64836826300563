import React from "react";

import {
  Card,
  CardBody,
  CardTitle,

} from "reactstrap";

const Opinions = [
  {
    src: "0",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placówki"
            className="img img-raised rounded"
            src={require("assets/img/logoopinia.png")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Współpracujemy z Lyke od kilku lat. Zakres naszego wspólnego
            działania opiera się na korzystaniu z kompletów pomocy sensorycznych
            SensoLyke oraz wdrożenia metody SensoLyke, przekazanej w postaci
            kursu on-line. <br /> Główne zalety współpracy z Lyke to kompleksowe
            podejście do usługi, czyli dostarczanie nie tylko pomocy
            sensorycznych, ale i gotowej instrukcji w postaci autorskiego
            warsztatu metodycznego. Dzięki temu z narzędzi SensoLyke możemy
            korzystać maksymalnie efektywnie. <br /> Z zaciekawieniem i
            otwartością czekamy na kolejne propozycje firmy, oby równie
            kompleksowe i profesjonalne.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Przedszkole nr 116 z Oddziałami Integracyjnymi i Specjalnymi w
            Poznaniu
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "1",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/logoopinia2.png")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Korzystamy z kompletu pomocy sensorycznych SensoLyke i jesteśmy
            bardzo zadowoleni. Dzieci z naszego przedszkola również. Firma Lyke
            nie tylko zaopatrzyła nas w pomoce, ale również przeprowadziła
            warsztaty z nauczycielami, aby w pełni mogli wykorzystać możliwości
            metody SensoLyke. Polecamy!
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Przedszkole w Baranowie "Mali Odkrywcy"
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "2",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/logoopinia3.png")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Jesteśmy zadowoleni ze współpracy z firmą Lyke. Ich usługi są na
            wysokim poziomie, a obsługa pomocna. Z czystym sumieniem polecamy
            współpracę z Lyke!
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Przedszkole "Na Zielonym Wzgórzu" w Lusówku
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "3",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/logoopinia4.png")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Jako Przedszkole nr 44 współpracujemy z Lyke kolejny rok. Korzystamy
            z kompletów pomocy sensorycznych SensoLyke; uczestniczyliśmy też w
            dedykowanym w/w pomocom kursie on-line. Jesteśmy zadowoleni z
            efektów współpracy. Nie tylko ze względu na jakość usługi, ale
            przede wszystkim przez świeże spojrzenie na kwestie zdrowia i
            rozwoju dzieci. Pomoce sensoryczne charakteryzują się wysoką
            jakością, są starannie wykonane. Przedstawiciel wykazuje się za
            każdym razem dużym zaangażowaniem, profesjonalizmem, kompetencją.
            Firma spełnia nasze oczekiwania, co pozwala nam rekomendować ją jako
            godnego zaufania partnera.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Przedszkole 44 im. Janusza Korczaka
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "4",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/logoopinia5.png")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Przedszkole w Bielawach nawiązało współpracę z firmą Lyke w bieżącym
            roku szkolnym, oferta trafiła w nasze potrzeby i była przyczynkiem
            wielu ciekawych aktywności w pracy z dziećmi. Jesteśmy zadowoleni ze
            współpracy z Lyke, z czystym sumieniem polecamy.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Przedszkole w Bielawach
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "5",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/logoopinia6.png")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            SensoLyke to opcja warta polecenia dla każdego przedszkola. U nas
            sprawdziło się świetnie. Same pomoce sensoryczne są użyteczne i
            bardzo przyjemne, dzieci lubią z nich korzystać i intuicyjnie wiedzą
            jak to robić. Kurs dla pedagogów z zabawami to dobre uzupełnienie i
            podpowiada jak efektywnie wykorzystać pomoce SensoLyke. Kompleksowe
            rozwiązanie, które polecamy.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Zespół Szkolno-Przedszkolny w Skulsku
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "6",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/logoopinia7.png")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            W roku 2021/2022 Przedszkole Kasztanowa Kraina w Przytocznej
            nawiązaliśmy kontakt z firmą Lyke celem dostarczenia pomocy
            sensorycznych. Główne zalety współpracy z Lyke to kompleksowe
            podejście do usługi, czyli dostarczenie nie tylko pomocy
            sensorycznych, ale i gotowej instrukcji w postaci autorskiego
            warsztatu metodycznego. Dzięki temu z narzędzi SensoLyke możemy
            korzystać maksymalnie efektywnie. Nasze dzieci odprężają się w
            trakcie zajęć.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Przedszkole Kasztanowa Kraina
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
];

export default Opinions;
