import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const LykePreFooter = () => {
  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2
            className="title"
            style={{
              marginBottom: "0px",
              marginTop: "100px",
              fontSize: "21px",
            }}
          >
            Masz pytania dotyczące{" "}
            <span style={{ color: "#024d9c", fontWeight: "bold" }}>
              SensoLyke
            </span>
            ?
          </h2>
        </Col>
        <Col className="text-center preFooterTxt ml-auto mr-auto mt-5" md="8">
          <h3 style={{ fontSize: "16px" }}>
            Pomożemy Ci dobrać idealny zestaw Bukinów, odpowiemy na wszystkie
            pytania i doradzimy, które rozwiązania będą dla Ciebie najlepsze.
          </h3>
        </Col>
        <Col
          className="text-center ml-auto mr-auto"
          style={{ marginBottom: "40px" }}
          md="8"
        >
          <div style={{ textAlign: "center" }}>
            <Link
              className="preFooterBtn"
              style={{ margin: "20px auto" }}
              to={`/kontakt`}
            >
              SKONTAKTUJ SIĘ{" "}
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LykePreFooter;
