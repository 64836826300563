import React from "react";
import L, { Icon } from "leaflet";

let eduIcon = <Icon />;
let sensoIcon = <Icon />;
let kidsIcon = <Icon />;

if (typeof window !== "undefined") {
  eduIcon = new L.Icon({
    iconUrl:
      "/static/eduMarker.png",
      shadowurl:
      "/static/markerShadow.png",
      iconSize: [19,29],
      shadowSize: [41, 41],
  });
  sensoIcon = new L.Icon({
    iconUrl:
      "/static/sensoMarker.png",
      shadowurl:
      "/static/markerShadow.png",
      iconSize: [19,29],
      shadowSize: [41, 41],
  });
  kidsIcon = new L.Icon({
    iconUrl:
      "/static/kidsMarker.png",
      shadowurl:
      "/static/markerShadow.png",
      iconSize: [19,29],
      shadowSize: [41, 41],
  });
}

const customers = [
  {
    lat: 54.1736171,
    lng: 18.484124201171838,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Zygmunta Bukowskiego w Czerniewie",
  },

  {
    lat: 54.1986228127315,
    lng: 18.42047326193877,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Ks. Jana Pawła Aeltermanna w Mierzeszynie",
  },

  {
    lat: 54.200199100000006,
    lng: 18.58541255595697,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Marii Kownackiej w Kłodawie",
  },

  {
    lat: 54.1447017,
    lng: 18.604625230487816,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Tadeusza Kościuszki w Sobowidzu",
  },

  {
    lat: 53.1637432,
    lng: 16.60934375258438,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół im. Janusza Korczaka w Szydłowie",
  },

  {
    lat: 53.2277926,
    lng: 16.6887096,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół im. W. Reymonta w Starej Łubiance",
  },

  {
    lat: 53.20288369539655,
    lng: 16.58058978208376,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Bohaterów Wyzwolenia Ziemi Wałeckiej w Skrzatuszu",
  },

  {
    lat: 52.64921045,
    lng: 16.81008630750014,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 2 im. Wojska Polskiego w Obornikach",
  },

  {
    lat: 52.6515574,
    lng: 16.803772223408473,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 3 im. Adama Mickiewicza w Obornikach",
  },

  {
    lat: 52.6523855,
    lng: 16.7894226,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa Nr 4 im. UNICEF w Obornikach",
  },

  {
    lat: 52.632334900000004,
    lng: 16.71416867670235,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Chrustowie",
  },

  {
    lat: 52.6801862,
    lng: 16.6730431,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Kiszewie",
  },

  {
    lat: 50.373760450000006,
    lng: 18.95338871556462,
    icon: eduIcon, type: "edu",
    title: "Miejska Szkoła Podstawowa nr 1 w Piekarach Śląskich",
  },

  {
    lat: 50.3825140445148,
    lng: 18.950370620953574,
    icon: eduIcon, type: "edu",
    title: "Miejska Szkoła Podstawowa nr 2 w Piekarach Śląskich",
  },

  {
    lat: 50.4104789,
    lng: 18.9478958,
    icon: eduIcon, type: "edu",
    title: "Zespól Szkolno - Przedszkolny nr 1 w Piekarach Śląskich",
  },

  {
    lat: 52.47727,
    lng: 18.33169,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Skulsku",
  },

  {
    lat: 52.4357968800514,
    lng: 18.303646788108797,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im Marszałka Edwarda Śmigłego Rydza",
  },

  {
    lat: 51.89616375,
    lng: 18.299554001824333,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Adama Chodyńskiego w Cekowie-Kolonii",
  },

  {
    lat: 51.88493,
    lng: 18.26527,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Kosmowie",
  },

  {
    lat: 51.84685495,
    lng: 18.272662686887333,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Morawinie",
  },

  {
    lat: 51.818942540345525,
    lng: 18.23425222925052,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Kamieniu",
  },

  {
    lat: 52.710641249999995,
    lng: 16.377516704605213,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 1 im. Janusza Korczaka we Wronkach",
  },

  {
    lat: 52.70713885,
    lng: 16.377626429039957,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 2 im. Kornela Makuszyńskiego we Wronkach",
  },

  {
    lat: 52.706562189733035,
    lng: 16.385599354298662,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 3. im. Zbigniewa Herberta we Wronkach ",
  },

  {
    lat: 52.6894551,
    lng: 16.307639881734374,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. W. Witosa Biezdrowo we Wronkach",
  },

  {
    lat: 52.6943396,
    lng: 16.3851399,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. A. Fiedlera Nowa Wieś Wronki",
  },

  {
    lat: 52.69618415,
    lng: 16.20810114747775,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Agnieszki Bartol Chojno - Wieś",
  },

  {
    lat: 51.81222175,
    lng: 17.017736722018952,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Ziemi Biskupiańskiej w Starej Krobi",
  },

  {
    lat: 51.70877725,
    lng: 16.99223457041809,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Nieparcie",
  },

  {
    lat: 52.36161155,
    lng: 18.186165435200838,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Kleczewie",
  },

  {
    lat: 52.39645765,
    lng: 18.131390699064944,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Juliana Tuwima w Złotkowie",
  },

  {
    lat: 52.387375,
    lng: 18.1978984,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Stanisława Staszica Sławoszewek",
  },

  {
    lat: 52.373368150000005,
    lng: 18.092613800884138,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Józefa Jóźwiaka w Koziegłowach",
  },

  {
    lat: 52.43707435,
    lng: 18.07744973247158,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Budzisławiu Kościelnym",
  },

  {
    lat: 54.4181463,
    lng: 17.4127414,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Łupawie",
  },

  {
    lat: 54.4810386,
    lng: 17.4825771,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Potęgowie",
  },

  {
    lat: 54.50509,
    lng: 17.52852,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Skórowie",
  },

  {
    lat: 52.593648,
    lng: 16.864006778703377,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Maniewie",
  },

  {
    lat: 52.6043538,
    lng: 16.76493388673706,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Objezierzu",
  },

  {
    lat: 52.6531048,
    lng: 16.6387752,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Sycynie",
  },

  {
    lat: 52.67482255,
    lng: 16.875103309772797,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Rożnowie",
  },

  {
    lat: 52.22407,
    lng: 16.44043,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa Ptaszkowo",
  },

  {
    lat: 52.23814,
    lng: 16.39358,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa Grąblewo",
  },

  {
    lat: 52.4742656,
    lng: 17.2878508,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Kazimierza Odnowiciela w Pobiedziskach",
  },

  {
    lat: 52.47184,
    lng: 17.23798,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół im. Konstytucji 3 Maja, lng: Szkoła Podstawowa i LO",
  },

  {
    lat: 52.470692400000004,
    lng: 17.171280590775126,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Królowej Jadwigi w Jerzykowie",
  },

  {
    lat: 52.50688,
    lng: 17.21616,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Maksymiliana Jackowskiego we Wronczynie",
  },

  {
    lat: 52.51508,
    lng: 17.30559,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Prof. Józefa Kostrzewskiego w Węglewie",
  },

  {
    lat: 52.4599448,
    lng: 17.172907810346288,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkoła Podstawowa i Przedszkole im. Bolesława Chrobrego w Biskupicach",
  },

  {
    lat: 52.47975,
    lng: 17.28327,
    icon: eduIcon, type: "edu",
    title: "Niepubliczna Szkoła Podstawowa Montessori",
  },

  {
    lat: 54.78271005,
    lng: 18.32262163200798,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Alojzego Stenzla w Strzelnie",
  },

  {
    lat: 54.746901199999996,
    lng: 18.380054788384868,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Leona Bizewskiego w Gnieżdżewie",
  },

  {
    lat: 54.64058935,
    lng: 18.339736657609553,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Wiktora Czapiewskiego w Rekowie Górnym",
  },

  {
    lat: 54.647698500000004,
    lng: 18.37049840280001,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno - Przedszkolny w Pochłowie",
  },

  {
    lat: 54.74862,
    lng: 18.30436,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Werblini ",
  },

  {
    lat: 54.2265,
    lng: 18.3663,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Pomlewie",
  },

  {
    lat: 54.16879715,
    lng: 18.27670548133463,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Trzepowie",
  },

  {
    lat: 54.196773300000004,
    lng: 18.326413097522234,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół - Szkoła Podstawowa",
  },

  {
    lat: 54.23304,
    lng: 18.26929,
    icon: eduIcon, type: "edu",
    title: "Publiczna Szkoła Podstawowa w Nowej Wsi Przywidzkiej",
  },

  {
    lat: 53.3866437,
    lng: 18.7339362,
    icon: eduIcon, type: "edu",
    title:
      'Szkoła Podstawowa im. Stanisława Broniewskiego "Orszy" w Wałdowie Szlacheckim',
  },

  {
    lat: 53.53443225,
    lng: 18.81965945062496,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. ks. Jana Twardowskiego w Mokrem",
  },

  {
    lat: 53.41983165,
    lng: 18.784901050629628,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa im. "Szarych Szeregów" w Piaskach',
  },

  {
    lat: 53.408464499999994,
    lng: 18.69445994168168,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Jana Pawła II w Sztynwagu",
  },

  {
    lat: 53.40980741991725,
    lng: 18.612904978693415,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Sosnówce",
  },

  {
    lat: 53.5151989,
    lng: 18.78454757851587,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Marii Konopnickiej w Nowej Wsi",
  },

  {
    lat: 52.33388475,
    lng: 16.7418635960222,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Arkadego Fiedlera w Chomęcicach",
  },

  {
    lat: 52.33882525,
    lng: 16.809707011774744,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 1 im. Janusza Korczaka w Komornikach ",
  },

  {
    lat: 52.337965749999995,
    lng: 16.80247641343596,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 2 im. Edwarda hr. Raczyńskiego w Komornikach",
  },

  {
    lat: 52.36296815,
    lng: 16.808270079037314,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Tytusa i Jana Działyńskich nr 1 w Plewiskach",
  },

  {
    lat: 52.37305675,
    lng: 16.806527266075875,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 2 im. Marii Skłodowskiej - Curie w Plewiskach",
  },

  {
    lat: 52.316619450000005,
    lng: 16.85195428714027,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Powstańców Wielkopolskich w Wirach ",
  },

  {
    lat: 52.4462112,
    lng: 16.1897348526119,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Lwówku",
  },

  {
    lat: 52.46767,
    lng: 16.08562,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkoły Podstawowej i Przedszkola w Zębowie",
  },

  {
    lat: 52.41673015,
    lng: 16.197397881728016,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkoły Podstawowej i Przedszkola w Chmielniku",
  },

  {
    lat: 52.4345998,
    lng: 16.2485825,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkoły Podstawowej i Przedszkola w Pakosławiu",
  },

  {
    lat: 51.7773271,
    lng: 17.051727746479145,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkoły Podstawowej i Przedszkola w Posadowie",
  },

  {
    lat: 52.43825635,
    lng: 16.294569403735686,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkoły i Przedszkola w Brodach",
  },

  {
    lat: 52.23863,
    lng: 18.47259,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Jana Pawła II w Wysokiem",
  },

  {
    lat: 52.2618562,
    lng: 18.421117980949106,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Olimpijczyków Polskich",
  },

  {
    lat: 52.27605,
    lng: 18.32415,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Stanisława Ciesiołkiewicza w Anielewie",
  },

  {
    lat: 52.444593460549015,
    lng: 18.46485832209334,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Sadlnie",
  },

  {
    lat: 52.44408984555968,
    lng: 18.5236090039482,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Boguszycach ",
  },

  {
    lat: 52.43862,
    lng: 18.58695,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Zaryniu",
  },

  {
    lat: 52.42439,
    lng: 18.40394,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Morzyczynie",
  },

  {
    lat: 52.40653375,
    lng: 18.470229943431704,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Zakrzewku",
  },

  {
    lat: 53.5347895,
    lng: 16.016719459237173,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 1 w Złocieńcu",
  },

  {
    lat: 53.54192895,
    lng: 16.048714072938154,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 3 w Złocieńcu",
  },

  {
    lat: 53.649488,
    lng: 16.08783608012513,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół w Nowym Worowie",
  },

  {
    lat: 51.71486065,
    lng: 17.22399511345659,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. J. Tuwima w Kobylinie",
  },

  {
    lat: 51.71542995164937,
    lng: 17.230854231225646,
    icon: eduIcon, type: "edu",
    title: "Branżowa Szkoła I Stopnia w Kobylinie",
  },

  {
    lat: 51.734587700000006,
    lng: 17.325884389695922,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Kuklinowie",
  },

  {
    lat: 51.705209249999996,
    lng: 17.16447136640517,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstaowa w Smolicach",
  },

  {
    lat: 51.75328992325504,
    lng: 17.190073799866404,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstaowa w Zalesiu Małym ",
  },

  {
    lat: 52.242852,
    lng: 18.3400201,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Woli Podłężnej im. Janusza Korczaka",
  },

  {
    lat: 52.2618562,
    lng: 18.421117980949106,
    icon: eduIcon, type: "edu",
    title: "Świetlica środowiskowa w Kramsku",
  },

  {
    lat: 52.31374315,
    lng: 16.5951236815216,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Jeziorkach",
  },

  {
    lat: 53.8225219,
    lng: 18.4657341,
    icon: eduIcon, type: "edu",
    title: "Publiczna Szkoła Podstawowa w Wielkim Bukowcu",
  },

  {
    lat: 52.14087815,
    lng: 16.75617993299373,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Bohaterów Westerplatte ",
  },

  {
    lat: 52.195961249999996,
    lng: 18.43969393914045,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Mikołaja Kopernika w Krzymowie",
  },

  {
    lat: 52.1725416,
    lng: 18.41934440864431,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Jana Brzechwy w Paprotni",
  },

  {
    lat: 52.2170418,
    lng: 18.3218002,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Szczepidle",
  },

  {
    lat: 52.19452,
    lng: 18.3037,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Marii Konopnickiej w Brzeźnie",
  },

  {
    lat: 52.20536,
    lng: 18.3919,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Borowie ",
  },

  {
    lat: 52.18661,
    lng: 18.36529,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Głodnie",
  },

  {
    lat: 52.1569,
    lng: 18.37394,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Smólniku",
  },

  {
    lat: 53.50088015,
    lng: 19.751532890396035,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Mikołaja Kopernika w Lubawie",
  },

  {
    lat: 50.3430055,
    lng: 19.0086997,
    icon: eduIcon, type: "edu",
    title: "Zespól Szkolno - Przedszkolny nr 2 w Piekarach Śląskich",
  },

  {
    lat: 50.3480629,
    lng: 18.9810236,
    icon: eduIcon, type: "edu",
    title: "Zespól Szkolno - Przedszkolny nr 3 w Piekarach Śląskich",
  },

  {
    lat: 50.38123255,
    lng: 18.93793897816532,
    icon: eduIcon, type: "edu",
    title:
      "Miejska Szkoła Podstawowa nr 5 im. Wawrzyńca Hajdy w Piekarach Śląskich",
  },

  {
    lat: 50.3959471,
    lng: 18.9587122,
    icon: eduIcon, type: "edu",
    title: "Miejska Szkola Podstawowa nr 9 z Oddzialami Integracyjnymi im. Gustawa Morcinka z wlaczonymi oddzialami Miejskiego Gimnazjum nr 1 w Piekarach Slaskich",
  },

  {
    lat: 50.386918800000004,
    lng: 18.9589354114351,
    icon: eduIcon, type: "edu",
    title: "Miejska Szkoła Podstawowa nr 11 w Piekarach Śląskich",
  },

  {
    lat: 50.3702663,
    lng: 18.97415973145089,
    icon: eduIcon, type: "edu",
    title: "Miejska Szkoła Podstawowa nr 12 im. Jana Demarczyka w Piekarach Śląskich",
  },

  {
    lat: 50.36506877400578,
    lng: 18.99777095864704,
    icon: eduIcon, type: "edu",
    title: "Miejska Szkoła Podstawowa nr 13 im. Powstańców Śląskich w Piekarach Śląskich",
  },

  {
    lat: 50.3383066,
    lng: 18.9634188,
    icon: eduIcon, type: "edu",
    title: "Miejska Szkola Podstawowa nr 15 im. Powstanców Slaskich w Piekarach Slaskich",
  },

  {
    lat: 53.15300125,
    lng: 19.54859302367307,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. ppor. Piotra Wysockiego w Świedziebni ",
  },

  {
    lat: 53.3807099,
    lng: 19.937193,
    icon: eduIcon, type: "edu",
    title: "Szkola Podstawowa im. Kawalerow Orderu Usmiechu w Rybnie",
  },

  {
    lat: 53.3246242,
    lng: 19.976317112234867,
    icon: eduIcon, type: "edu",
    title: "Szkola Podstawowa im. Janusza Korczaka w Koszelewach",
  },

  {
    lat: 53.341626250000004,
    lng: 19.985941379057678,
    icon: eduIcon, type: "edu",
    title: "Szkola Podstawowa im. Polskich Mistrzów Olimpijskich w Żabinach",
  },

  {
    lat: 53.2126829,
    lng: 19.5168771,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Jastrzębiu ",
  },

  {
    lat: 53.9957068,
    lng: 18.5235535,
    icon: eduIcon, type: "edu",
    title: "Publicznej Szkoły Podstawowej w Kokoszkowach",
  },

  {
    lat: 52.652158650000004,
    lng: 17.960659977189056,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 1 w Zespole Szkolno - Przedszkolnym w Mogilnie",
  },

  {
    lat: 52.6552418,
    lng: 17.95572962262563,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 2 w Mogilnie",
  },

  {
    lat: 52.6605912,
    lng: 17.9540247,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 3 w Mogilnie",
  },

  {
    lat: 52.6097528,
    lng: 17.93517153828158,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Wylatowie",
  },

  {
    lat: 51.375831000000005,
    lng: 16.953515566582183,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Prusicach",
  },

  {
    lat: 51.3699535,
    lng: 16.962754100360392,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Skokowej",
  },

  {
    lat: 51.3888212,
    lng: 16.8113038,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Piotrkowicach. Filia w Strupinie",
  },

  {
    lat: 54.76143,
    lng: 17.5579,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Łebie im. Adama Mickiewicza",
  },

  {
    lat: 50.5165662,
    lng: 16.5114123,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno - Przedszkolny ",
  },

  {
    lat: 52.5720114,
    lng: 15.681148960863764,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Janusza Kusocińskiego w Przytocznej ",
  },

  {
    lat: 52.5667998,
    lng: 15.807003524375371,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Marii Konopnickiej",
  },

  {
    lat: 52.35341252813933,
    lng: 14.5630062813583578,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 1 im. M. Kopernika w Słubicach",
  },

  {
    lat: 52.42942225,
    lng: 14.659037521187965,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Golicach",
  },

  {
    lat: 52.3505443,
    lng: 14.6316732,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. K. Makuszyńskiego w Kunowicach",
  },

  {
    lat: 52.5823321,
    lng: 15.008014081458064,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Wincentego Witosa w Krzeszycach",
  },

  {
    lat: 52.49069,
    lng: 19.24202,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno - Przedszkolny w Baruchowie",
  },

  {
    lat: 53.3167312,
    lng: 20.678504561851874,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Marszałka Polski Józefa Piłsudskiego w Janowie ",
  },

  {
    lat: 53.372259,
    lng: 20.609390332221253,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Mikołaja Kopernika w Muszakach",
  },

  {
    lat: 54.2396449,
    lng: 18.202594572388843,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Świętego Franciszka z Asyżu w Egiertowie",
  },

  {
    lat: 52.11837835,
    lng: 20.72561122398625,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Bohaterów Warszawy",
  },

  {
    lat: 53.82189245,
    lng: 14.977120886263977,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół Publicznych w Golczewie",
  },

  {
    lat: 52.2255234,
    lng: 20.2396285,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 3 im. B. Krzywoustego",
  },

  {
    lat: 52.23335145,
    lng: 20.238738942522907,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 4 im. Janusza Korczaka",
  },

  {
    lat: 52.2255361,
    lng: 20.24114,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 1 im. Jana Pawła II",
  },

  {
    lat: 52.23859505,
    lng: 20.24599759700933,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 6 im. W. Jagiełły",
  },

  {
    lat: 53.96536,
    lng: 14.96746,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Świerznie",
  },

  {
    lat: 53.948328069345465,
    lng: 15.010478437796197,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Stuchowie",
  },

  {
    lat: 52.87236805,
    lng: 18.685287709986582,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa Nr 1 im. Polskich Podróżników w Aleksandrowie Kujawskim",
  },

  {
    lat: 52.87738995,
    lng: 18.69960477213965,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa Nr 3 im. Józefa Wybickiego",
  },

  {
    lat: 52.87541317279226,
    lng: 18.688264774964363,
    icon: eduIcon, type: "edu",
    title: "Publiczna Szkoła Podstawowa Towarzystwa Salezjańskiego w Aleksandrowie Kujawskim",
  },

  {
    lat: 50.680855449999996,
    lng: 16.75506413258252,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. K. K. Baczyńskiego",
  },

  {
    lat: 51.92492815,
    lng: 20.626963105263123,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. mjr. Józefa Wiśniewskiego w Lutkówce",
  },

  {
    lat: 52.220316749999995,
    lng: 21.5437688855823,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Marii Konopnickiej w Brzózem",
  },

  {
    lat: 52.18781,
    lng: 21.6602,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. księdza Antoniego Tyszki w Janowie",
  },

  {
    lat: 53.0988445,
    lng: 18.3222797,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Janusza Korczaka w Przysieku",
  },

  {
    lat: 53.09890025,
    lng: 18.32865032204142,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Kornela Makuszyńskiego w Złejwsi Wielkiej ",
  },

  {
    lat: 52.153124,
    lng: 21.7367752,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolny w Cegłowie ",
  },

  {
    lat: 51.97457165,
    lng: 20.517849276200664,
    icon: eduIcon, type: "edu",
    title: "Szkoły Podstawowej im Maklakiewicza w Mszczonowie",
  },

  {
    lat: 52.150789599999996,
    lng: 21.591278219240948,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Generała Józefa Hallera w Mariance",
  },

  {
    lat: 52.86641,
    lng: 16.68372,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Hucie",
  },

  {
    lat: 52.9488118,
    lng: 16.5933909,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. 67 Pułku Piechoty w Romanowie Dolnym",
  },

  {
    lat: 52.9032529,
    lng: 16.6896887,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Leśników Polskich w Gębicach",
  },

  {
    lat: 52.9395325,
    lng: 16.6578527,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Sarbi",
  },

  {
    lat: 52.8827456,
    lng: 16.590912508027138,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Śmieszkowie",
  },

  {
    lat: 52.91055,
    lng: 16.39117,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Jędrzejewie",
  },

  {
    lat: 52.947972,
    lng: 16.5122966,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Powstańców Wielkopolskich 1918-1919 w Kuźnicy Czarnkowskiej",
  },

  {
    lat: 52.905813,
    lng: 16.5628015,
    icon: eduIcon, type: "edu",
    title: "Czarnków dla Rodziców dla Gminy",
  },

  {
    lat: 52.22469611794955,
    lng: 16.529080735995702,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny Szkoła Podstawowa im. Powstańców Wielkopolskich w Granowie",
  },

  {
    lat: 51.071850049999995,
    lng: 16.100143274127888,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno-Przedszkolny w Męcince",
  },

  {
    lat: 51.9259826,
    lng: 21.090791703666646,
    icon: eduIcon, type: "edu",
    title: "Publiczna Szkoła Podstawowa im. Jana Brzechwy w Sułkowicach",
  },

  {
    lat: 52.1494601,
    lng: 21.50329729823718,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Marszałka Józefa Piłsudskiego w Zamieniu",
  },

  {
    lat: 51.682326891381365,
    lng: 20.952263609658715,
    icon: eduIcon, type: "edu",
    title: "Promna Publiczna Szkoła Podstawowa im. Tadeusza Kierzkowskiego",
  },

  {
    lat: 51.72960995,
    lng: 20.96453318810399,
    icon: eduIcon, type: "edu",
    title: "Olkowice Publiczna Szkoła Podstawowa im. Marii Konopnickiej w Olkowicach",
  },

  {
    lat: 51.6655235,
    lng: 20.854947,
    icon: eduIcon, type: "edu",
    title: "Zespół Placówek Oświatowych w Przybyszewie, lng: Publiczna Szkoła Podstawowa im. Władysława Rosłońca",
  },

  {
    lat: 52.602136,
    lng: 20.479182606426697,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Lisewie",
  },

  {
    lat: 52.6012195,
    lng: 20.412432663547484,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Siedlinie",
  },

  {
    lat: 51.50951645000001,
    lng: 18.111914623060287,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. UNICEF w Grabowie Nad Prosną",
  },

  {
    lat: 51.4614669,
    lng: 18.0902611,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Marszałkach",
  },

  {
    lat: 51.4690903,
    lng: 18.0525546,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Bukownicy",
  },

  {
    lat: 54.5457741,
    lng: 18.1500793,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. płk S.Dąbka w Sychowie",
  },

  {
    lat: 54.51054875,
    lng: 18.08056261748913,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Janusza Korczaka w Wyszecinie",
  },

  {
    lat: 54.53255965,
    lng: 18.099374741386036,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. księdza Jana Twardowskiego w Barłominie",
  },

  {
    lat: 53.00495,
    lng: 21.30335,
    icon: eduIcon, type: "edu",
    title: "Zespół Placówek Oświatowych w Sypniewie",
  },

  {
    lat: 52.9756209,
    lng: 21.220046,
    icon: eduIcon, type: "edu",
    title: "Zespół Placówek Oświatowych w Gąsewie Poduchownym",
  },

  {
    lat: 52.48822,
    lng: 17.42805,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Fałkowie",
  },

  {
    lat: 52.5487769,
    lng: 17.4540084,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Tadeusza Kościuszki w Owieczkach",
  },

  {
    lat: 52.5131726,
    lng: 17.4559206,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Łubowie",
  },

  {
    lat: 50.0134307,
    lng: 18.4365668,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno - Przedszkolny nr 4",
  },

  {
    lat: 52.8884825,
    lng: 20.4689114,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Chotumiu ",
  },

  {
    lat: 51.97946,
    lng: 20.35045,
    icon: eduIcon, type: "edu",
    title: "Zespołu Szkolno - Przedszkolnego w Puszczy Mariańskiej",
  },

  {
    lat: 52.00148,
    lng: 20.27119,
    icon: eduIcon, type: "edu",
    title: "Zespołu Szkolno - Przedszkolnego w Bartnikach",
  },

  {
    lat: 53.90641,
    lng: 18.2107,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół Publicznych w Kaliskach",
  },

  {
    lat: 52.22606105,
    lng: 17.283638294679566,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 2",
  },

  {
    lat: 52.2083858,
    lng: 17.416351857577155,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa w Starkówcu Piątkowskim",
  },

  {
    lat: 54.4401026,
    lng: 17.7398515,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkół w Cewicach",
  },

  {
    lat: 51.51400855,
    lng: 20.945069099261097,
    icon: eduIcon, type: "edu",
    title: "Publiczna Szkoła Podstawowa im. Ojca Świętego Jana Pawła II w Starej Błotnicy",
  },

  {
    lat: 51.5621101,
    lng: 21.027918015261413,
    icon: eduIcon, type: "edu",
    title: "Publiczna Szkoła Podstawowa im. Marii Konopnickiej w Starym Goździe",
  },

  {
    lat: 52.15426942189791,
    lng: 20.92289893367807,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Cypriana Godebskiego w Raszynie",
  },

  {
    lat: 49.9905395,
    lng: 18.493241,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno - Przedszkolny nr 2",
  },

  {
    lat: 50.02311511879025,
    lng: 18.46695575021724,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno - Przedszkolny nr 3",
  },

  {
    lat: 51.51144885,
    lng: 21.119576749566313,
    icon: eduIcon, type: "edu",
    title: "Gmina Jedlińsk",
  },

  {
    lat: 52.22678095,
    lng: 16.374512044853176,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 1 im. Olimpijczyków Polskich ",
  },

  {
    lat: 52.2263171,
    lng: 16.36398668520281,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 2",
  },

  {
    lat: 52.21666,
    lng: 16.35265,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa nr 4",
  },

  {
    lat: 52.21029,
    lng: 16.57473,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa Kąkolewo ",
  },

  {
    lat: 52.22675035,
    lng: 16.37236949184019,
    icon: eduIcon, type: "edu",
    title: "Przedszkole Gminne im. Krasnala Hałabały w Grodzisku Wielkopolskim",
  },

  {
    lat: 52.3787612,
    lng: 16.9664821,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 178 w Poznaniu ",
  },

  {
    lat: 52.39806,
    lng: 16.8687217,
    icon: sensoIcon, type: "senso",
    title: "Zespół Przedszkoli nr 2 w Poznaniu: Przedszkole nr 83",
  },

  {
    lat: 52.3979023,
    lng: 16.86681382837744,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 47",
  },

  {
    lat: 52.2280688,
    lng: 16.830713986605147,
    icon: sensoIcon, type: "senso",
    title: "Akademia Odkrywców w Krośnie",
  },

  {
    lat: 52.22360865,
    lng: 16.365422198435702,
    icon: sensoIcon, type: "senso",
    title: "Gmina Grodzisk Wielkopolski",
  },

  {
    lat: 52.652798,
    lng: 16.8192636,
    icon: sensoIcon, type: "senso",
    title: "Gmina Oborniki ",
  },

  {
    lat: 52.4307453,
    lng: 16.780419904604756,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Pod Muchomorkiem w Poznaniu Przedszkole prywatne. Wojtyniak a",
  },

  {
    lat: 52.392554700000005,
    lng: 16.90121808140139,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 44 w Poznaniu im. J. Korczaka",
  },

  {
    lat: 52.33388475,
    lng: 16.7418635960222,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Chomęcicach im. Arkadego Fiedlera",
  },

  {
    lat: 52.338278,
    lng: 16.8110976,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr  1 im. Janusza Korczaka w Komornikach ",
  },

  {
    lat: 52.337965749999995,
    lng: 16.80247641343596,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 2 im. Edwarda hr. Raczyńskiego w Komornikach",
  },

  {
    lat: 52.36296815,
    lng: 16.808270079037314,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 1 w Plewiskach im. Tytusa i Jana Działyńskich",
  },

  {
    lat: 52.37305675,
    lng: 16.806527266075875,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 2 w Plewiskachm. Marii Skłodowskiej - Curie ",
  },

  {
    lat: 52.316619450000005,
    lng: 16.85195428714027,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Wirach im. Powstańców Wielkopolskich",
  },

  {
    lat: 52.38166,
    lng: 17.07394,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Nr 1 w Zalasewie ”Malowany Konik” ",
  },

  {
    lat: 52.05783495,
    lng: 16.71290275952122,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkół Gminy Kościan Przedszkole i Szkoła Podstawowa w Racocie ",
  },

  {
    lat: 52.23863,
    lng: 18.47259,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Wysokiem,m. Jana Pawła II ",
  },

  {
    lat: 52.2618562,
    lng: 18.421117980949106,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Olimpijczyków Polskich",
  },

  {
    lat: 52.27605,
    lng: 18.32415,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Anielewie im. Stanisława Ciesiołkiewicza",
  },

  {
    lat: 52.30817,
    lng: 18.41404,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa Helenów Drugi - Święciec",
  },

  {
    lat: 52.2618562,
    lng: 18.421117980949106,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Gminne w Kramsku",
  },

  {
    lat: 52.47727,
    lng: 18.33169,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny w Skulsku",
  },

  {
    lat: 52,
    lng: 17,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Kuklinowie",
  },

  {
    lat: 51.705209249999996,
    lng: 17.16447136640517,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Smolicach",
  },

  {
    lat: 51.7533,
    lng: 17.18995,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstaowa w Zalesiu Małym ",
  },

  {
    lat: 52.196347599999996,
    lng: 18.282017703382436,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Woli Podłężnej im. Janusza Korczaka",
  },

  {
    lat: 52.2618562,
    lng: 18.421208002386294,
    icon: sensoIcon, type: "senso",
    title: "Świetlica środowiskowa w Kramsku",
  },

  {
    lat: 53.93362755,
    lng: 18.32609967355574,
    icon: sensoIcon, type: "senso",
    title: "Gmina Zblewo",
  },

  {
    lat: 52.44164165,
    lng: 16.79007837411628,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Mali Odkrywcy w Baranowie",
  },

  {
    lat: 52.4246945,
    lng: 16.644916069833137,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Na Zielonym Wzgórzu w Lusówku",
  },

  {
    lat: 53.78678,
    lng: 18.61215,
    icon: sensoIcon, type: "senso",
    title: "Publiczna Szkoła Podstawowa w Barłożnie im. Ks. Senatora Feliksa Bolta",
  },

  {
    lat: 52.3697584,
    lng: 18.1779879008991,
    icon: sensoIcon, type: "senso",
    title: "Środowiskowe Ognisko Wychowawcze TPD Kleczew ",
  },

  {
    lat: 52.36161155,
    lng: 18.186165435200838,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno - Przedszkolny w Kleczewie",
  },

  {
    lat: 52.43649395,
    lng: 18.076570671275093,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno – Przedszkolny w Budzisławiu Kościelnym",
  },

  {
    lat: 52.387375,
    lng: 18.1978984,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Sławoszewek im. Stanisława Staszica w Sławoszewek",
  },

  {
    lat: 52.444771450000005,
    lng: 16.99034016296993,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Koziegłowach im. Józefa Jóźwiaka w Koziegłowach ",
  },

  {
    lat: 52.39645765,
    lng: 18.131390699064944,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Złotkowie im. Juliana Tuwima w Złotkowie",
  },

  {
    lat: 52.277909388645874,
    lng: 16.99023873444213,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Leśna Drużyna w Kamionkach",
  },

  {
    lat: 53.82193242882423,
    lng: 14.977160064794337,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkół Publicznych w Golczewie",
  },

  {
    lat: 53.153069350699106,
    lng: 19.54840062280402,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Świedziebni im. ppor. Piotra Wysockiego w Świedziebni",
  },

  {
    lat: 54.241876561000836,
    lng: 17.222260513403526,
    icon: sensoIcon, type: "senso",
    title: "Gminne Przedszkole w Kołczygłowach",
  },

  {
    lat: 52.87409,
    lng: 19.17804,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Jastrzębiu ",
  },

  {
    lat: 53.385996574552735,
    lng: 19.930095373760242,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole w Rybnie",
  },

  {
    lat: 50.8516335,
    lng: 16.016038525897805,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny w Marciszowie",
  },

  {
    lat: 50.98014055,
    lng: 16.65324196565727,
    icon: sensoIcon, type: "senso",
    title: "Publiczne Przedszkole w Mietkowie",
  },

  {
    lat: 54.76175739958639,
    lng: 17.55760089919344,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Łebie im. Adama Mickiewicza",
  },

  {
    lat: 52.5981996,
    lng: 18.035206,
    icon: sensoIcon, type: "senso",
    title: "Przedszkola w Gębicach",
  },

  {
    lat: 52.57205634506451,
    lng: 15.681208118904085,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Janusza Kusocińskiego w Przytocznej ",
  },

  {
    lat: 52.57066639999999,
    lng: 15.801171146606217,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa Wierzbno im. Marii Konopnickiej ",
  },

  {
    lat: 52.577370258757355,
    lng: 15.674792249841097,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Kasztanowa Kraina w Przytoczna",
  },

  {
    lat: 52.5823321,
    lng: 15.008014081458064,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Gminne im. Misia Uszatka w Krzeszycach",
  },

  {
    lat: 52.5823321,
    lng: 15.008014081458064,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Wincentego Witosa w Krzeszycach",
  },

  {
    lat: 51.59585545,
    lng: 16.0484609670703,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Gminne " Kraina Marzeń" Jerzmanowa',
  },

  {
    lat: 51.6237168,
    lng: 16.0738515,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Gminne w Jaczowie",
  },

  {
    lat: 51.2880257,
    lng: 18.788365760079685,
    icon: sensoIcon, type: "senso",
    title: "Publiczne Przedszkole w Osjakowie",
  },

  {
    lat: 51.8218332,
    lng: 17.2315164,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Samorządowe w Pogorzeli",
  },

  {
    lat: 50.69529545,
    lng: 16.63284572185389,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Miejskie w Bielawie",
  },

  {
    lat: 50.82255304321894,
    lng: 15.529019492692196,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 1 w Szklarskiej Porębie",
  },

  {
    lat: 52.23859505,
    lng: 20.24599759700933,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 6 im. W. Jagiełły",
  },

  {
    lat: 51.1861,
    lng: 15.87207,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno - Przedszkolny w Zagrodnie",
  },

  {
    lat: 50.680855449999996,
    lng: 16.75506413258252,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. K.K. Baczyńskiego",
  },

  {
    lat: 54.2396449,
    lng: 18.202594572388843,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Świętego Franciszka z Asyżu w Egiertowie",
  },

  {
    lat: 52.220316749999995,
    lng: 21.5437688855823,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Marii Konopnickiej w Brzózem",
  },

  {
    lat: 53.0988445,
    lng: 18.3222797,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Janusza Korczaka w Przysieku",
  },

  {
    lat: 51.73149,
    lng: 17.06331,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa w Pępowie im. Powstańców Wielkopolskich",
  },

  {
    lat: 51.41968,
    lng: 18.07734,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Publiczne w Doruchowie",
  },

  {
    lat: 52.153124,
    lng: 21.7367752,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolny w Cegłowie ",
  },

  {
    lat: 51.92492815,
    lng: 20.626963105263123,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im J. Wiśniewskiego w Lutkówce",
  },

  {
    lat: 51.97457165,
    lng: 20.517849276200664,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Maklakiewicza w Mszczonowie ",
  },

  {
    lat: 52.2197214,
    lng: 16.534009206238878,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny Szkoła Podstawowa im. Powstańców Wielkopolskich w Granowie",
  },

  {
    lat: 51.07181772998584,
    lng: 16.100110089979175,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny w Męcince",
  },

  {
    lat: 52.1494601,
    lng: 21.50329729823718,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. Marszałka Józefa Piłsudskiego w Zamieniu",
  },

  {
    lat: 52.8407684,
    lng: 20.5037288,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. 11 Pułku Ułanów Legionowych w Gumowie ",
  },

  {
    lat: 51.312411499999996,
    lng: 17.72628733954728,
    icon: eduIcon, type: "edu",
    title: "Publiczne Przedszkole nr 1 im. Koszałka Opałka",
  },

  {
    lat: 51.3116794,
    lng: 17.718584024260643,
    icon: eduIcon, type: "edu",
    title: "Publiczne Przedszkole nr 2 im. Misia Uszatka",
  },

  {
    lat: 51.305366250000006,
    lng: 17.713802507246143,
    icon: eduIcon, type: "edu",
    title: "Publiczne Przedszkole nr 3 im. Małego Księcia",
  },

  {
    lat: 51.308729,
    lng: 17.7185789,
    icon: eduIcon, type: "edu",
    title: "Publiczne Przedszkole nr 4 w Sycowie ",
  },

  {
    lat: 54.4840785,
    lng: 17.4846271,
    icon: eduIcon, type: "edu",
    title: "Przedszkole w Potęgowie",
  },

  {
    lat: 52.4953661,
    lng: 17.4234201,
    icon: eduIcon, type: "edu",
    title: "Przedszkole nr 1 w Fałkowie",
  },

  {
    lat: 52.5118892,
    lng: 17.5056052,
    icon: eduIcon, type: "edu",
    title: "Przedszkole nr 2 Tęczowy Domek ",
  },

  {
    lat: 52.65118315,
    lng: 16.79519389232715,
    icon: eduIcon, type: "edu",
    title: 'Przedszkole nr 1 "Bajeczka" w Obornikach',
  },

  {
    lat: 52.6581685,
    lng: 16.8280407,
    icon: eduIcon, type: "edu",
    title: 'Przedszkole nr 4 "Bajka" w Obornikach',
  },

  {
    lat: 52.64982335,
    lng: 16.815402638529413,
    icon: eduIcon, type: "edu",
    title: "Zespół Przedszkoli w Obornikach",
  },

  {
    lat: 50.01042775,
    lng: 18.470400534535912,
    icon: eduIcon, type: "edu",
    title: "Zespół Szkolno - Przedszkolny nr 7",
  },

  {
    lat: 49.9348853,
    lng: 19.9608672,
    icon: eduIcon, type: "edu",
    title: "Przedszkole Samorządowe nr 1 w Świątnikach Górnych",
  },

  {
    lat: 49.9337734,
    lng: 19.9517806,
    icon: eduIcon, type: "edu",
    title: "Przedszkola Samorządowego nr 2 w Świątnikach Górnych",
  },

  {
    lat: 49.9761834,
    lng: 18.5005299,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkół nr 1 w Wodzisławiu Śląskim",
  },

  {
    lat: 54.7005992,
    lng: 18.67370706427337,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. J. Wybickiego",
  },

  {
    lat: 54.366861150000005,
    lng: 17.757200623337418,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa z oddziałami integracyjnym im. Leśników Polskich w Przerytem",
  },

  {
    lat: 52.19713045,
    lng: 21.59313977451341,
    icon: eduIcon, type: "edu",
    title: "Szkoła Podstawowa im. Rodziny Sażyńskich w Starej Niedziałce",
  },

  {
    lat: 50.010854949999995,
    lng: 18.451325613617275,
    icon: eduIcon, type: "edu",
    title: "Publiczne Przedszkole nr 15 ",
  },

  {
    lat: 50.0017998,
    lng: 18.457861643119266,
    icon: eduIcon, type: "edu",
    title: "Przedszkole nr 1 ",
  },

  {
    lat: 52.62911615,
    lng: 20.354920749162883,
    icon: kidsIcon, type: "kidsup",
    title: 'Językowo-Sportowe Niepubliczny Żłobek "Mali Giganci"',
  },

  {
    lat: 52.629069900000005,
    lng: 20.365233698688524,
    icon: kidsIcon, type: "kidsup",
    title: 'Językowo-Sportowe Niepubliczny Żłobek "Mali Giganci"',
  },

  {
    lat: 52.6171494,
    lng: 20.37455073462329,
    icon: kidsIcon, type: "kidsup",
    title: 'Językowo-Sportowe Niepubliczny Żłobek "Mali Giganci"',
  },

  {
    lat: 54.47901725,
    lng: 18.50232044799261,
    icon: kidsIcon, type: "kidsup",
    title: 'Punkt Przedszkolny "Kids Only" oraz Żłobek "BABIES! Only"',
  },

  {
    lat: 52.3971951,
    lng: 16.9226477,
    icon: kidsIcon, type: "kidsup",
    title: "Żłobek Niutek 1",
  },

  {
    lat: 52.4190838,
    lng: 16.872819797006017,
    icon: kidsIcon, type: "kidsup",
    title: "Żłobek Niutek 2",
  },

  {
    lat: 52.4094591,
    lng: 16.8855629,
    icon: kidsIcon, type: "kidsup",
    title: "Żłobek Niutek 3",
  },

  {
    lat: 52.4133849,
    lng: 16.922126864915406,
    icon: kidsIcon, type: "kidsup",
    title: "Żłobek Niutek 5",
  },

  {
    lat: 50.2130851,
    lng: 18.986152884521495,
    icon: kidsIcon, type: "kidsup",
    title: 'Centrum Edukacji "Familia"',
  },

  {
    lat: 50.1982759,
    lng: 18.99145,
    icon: kidsIcon, type: "kidsup",
    title: 'Centrum Edukacji "Familia"',
  },

  {
    lat: 50.34796288508059,
    lng: 18.206861921507254,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczny Żłobek "Wesoła Gromadka"',
  },

  {
    lat: 52.68065,
    lng: 16.71911,
    icon: kidsIcon, type: "kidsup",
    title: "Zespół Szkolno–Przedszkolny",
  },

  {
    lat: 54.543017000000006,
    lng: 18.46689362297426,
    icon: kidsIcon, type: "kidsup",
    title: 'Dwujęzyczne przedszkole "Wild Fox" o profilu Montessori',
  },

  {
    lat: 50.6840752,
    lng: 16.6217996,
    icon: kidsIcon, type: "kidsup",
    title: "Żłobek w Bielawie",
  },

  {
    lat: 50.821480550000004,
    lng: 16.274090049791894,
    icon: kidsIcon, type: "kidsup",
    title: "Zespół Przedszkoli Samorządowych nr 1 ( nr 14 oraz nr 8)",
  },

  {
    lat: 52.65269335,
    lng: 16.78958039695536,
    icon: kidsIcon, type: "kidsup",
    title: "Szkoła Podstawowa nr 4 im. UNICEF w Obornikach",
  },

  {
    lat: 51.78940966625179,
    lng: 19.39606220360412,
    icon: kidsIcon, type: "kidsup",
    title: 'Akademia Przedszkolaka "Małe Orły"',
  },

  {
    lat: 53.56478045,
    lng: 20.984084457675856,
    icon: kidsIcon, type: "kidsup",
    title: 'Przedszkole Niepubliczne "Pod Topolą"',
  },

  {
    lat: 51.58580325,
    lng: 18.963465622900316,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole "Maluszkowo"',
  },

  {
    lat: 52.7491844,
    lng: 16.9995637,
    icon: kidsIcon, type: "kidsup",
    title: "Przedszkole Nr 1 im. Kubusia Puchatka",
  },

  { lat: 52.74942,
    lng: 16.98978,
    icon: kidsIcon, type: "kidsup",
    title: "Przedszkole nr 2",
   },

  {
    lat: 50.80475,
    lng: 23.88652,
    icon: kidsIcon, type: "kidsup",
    title: "Przedszkole Miejskie nr 2 ",
  },

  {
    lat: 52.8184208,
    lng: 16.8248234,
    icon: kidsIcon, type: "kidsup",
    title: "Przedszkole Publiczne w Ryczywole",
  },

  {
    lat: 52.7139406,
    lng: 16.392125059516722,
    icon: kidsIcon, type: "kidsup",
    title: 'Grupa Pro Alegria - Alicja Helbik "Amica Kids"',
  },

  {
    lat: 50.6907954,
    lng: 16.62157088,
    icon: kidsIcon, type: "kidsup",
    title: "Niepubliczne Przedszkole Montessori z oddziałami integracyjnymi",
  },

  {
    lat: 50.68902745,
    lng: 16.616317660338236,
    icon: kidsIcon, type: "kidsup",
    title: "Niepubliczne Przedszkole Montessori z oddziałami integracyjnymi",
  },

  {
    lat: 52.650525200000004,
    lng: 16.816148393130078,
    icon: kidsIcon, type: "kidsup",
    title: 'Zespół Przedszkoli w Obornikach (Przedszkole nr 5 "Kolorowe Nutki", Przedszkole nr 2 "Słoneczny Świat"',
  },

  {
    lat: 52.6581685,
    lng: 16.8280407,
    icon: kidsIcon, type: "kidsup",
    title: 'Przedszkole nr 4 "Bajka"',
  },

  {
    lat: 52.3329977,
    lng: 20.965313003658096,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole "Promyki"',
  },

  {
    lat: 52.5104,
    lng: 16.57692,
    icon: kidsIcon, type: "kidsup",
    title: 'Przedszkole "Krasnal Hałabała"',
  },

  {
    lat: 53.4649312,
    lng: 18.7304998,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole "Bambino"',
  },

  {
    lat: 52.39628,
    lng: 17.24371,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole "Akademia Pomysłów"',
  },

  {
    lat: 51.7840259,
    lng: 20.8496999,
    icon: kidsIcon, type: "kidsup",
    title: "Publiczna Szkoła Podstawowa im. UNICEF w Belsku Dużym",
  },

  {
    lat: 52.05399012893223,
    lng: 20.44453761397346,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole i Żłobek Dwujęzyczne "Stacyjkowo"',
  },

  {
    lat: 52.05337996552314,
    lng: 20.449719755878252,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole i Żłobek Dwujęzyczne "Stacyjkowo"',
  },

  {
    lat: 49.9394574,
    lng: 18.704861,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole "Eduś"',
  },

  {
    lat: 52.48688415,
    lng: 16.50258268196812,
    icon: kidsIcon, type: "kidsup",
    title: "Zespół Szkolno - Przedszkolny w Bytyniu",
  },

  {
    lat: 53.761338949999995,
    lng: 20.494963229090448,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczny Żłobek i Przedszkole "Akademia Prymusa"',
  },

  {
    lat: 52.405690750000005,
    lng: 16.78207193555189,
    icon: kidsIcon, type: "kidsup",
    title: 'Niepubliczne Przedszkole i Żłobek Artystyczno-Ekologiczne "LA LO LI"',
  },

  {
    lat: 52.714454599999996,
    lng: 16.391506290090412,
    icon: kidsIcon, type: "kidsup",
    title: 'Przedszkole nr 1 "Bajkowy Świat"',
  },

  {
    lat: 52.42394,
    lng: 16.9062739,
    icon: kidsIcon, type: "kidsup",
    title: 'Prywatne Przedszkole Montessori "Bambini"',
  },

  {
    lat: 52.70841465,
    lng: 16.37456714143543,
    icon: kidsIcon, type: "kidsup",
    title: 'Przedszkole nr 2 "Wronczusie"',
  },

  {
    lat: 51.271297450000006,
    lng: 15.557633712968908,
    icon: kidsIcon, type: "kidsup",
    title: "Miejskie Przedszkole Publiczne nr 3 w Bolesławcu",
  },

  {
    lat: 52.2333082539816,
    lng: 20.239032260576028,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 4 im. Janusza Korczaka",
  },

  {
    lat: 52.22548168341497,
    lng: 20.241151805800722,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 1 im. Jana Pawła II",
  },

  {
    lat: 52.2255234,
    lng: 20.2396285,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 3 im. B. Krzywoustego",
  },

  {
    lat: 51.2737933,
    lng: 22.5804342,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Niepubliczne „Mali Giganci II”",
  },

  {
    lat: 51.755128,
    lng: 18.086226835062057,
    icon: sensoIcon, type: "senso",
    title: 'Fundacja "Nowa Nadzieja"',
  },

  {
    lat: 52.33329215,
    lng: 16.809770164148127,
    icon: sensoIcon, type: "senso",
    title: "Publiczne Przedszkole Raz Dwa Trzy w Komornikach",
  },

  {
    lat: 52.246403739586476,
    lng: 20.96663505212899,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczna Szkoła Podstawowa Pod Wielkim Dachem Nieba",
  },

  {
    lat: 52.3829007,
    lng: 16.9499342,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 114",
  },

  {
    lat: 52.4323277,
    lng: 16.703293798884058,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa im. gen. J. Dowbora Muśnickiego w Lusowie",
  },

  {
    lat: 52.46267164375436,
    lng: 16.91258380009691,
    icon: sensoIcon, type: "senso",
    title: "Kraina Dzieci Przedszkole Niepubliczne",
  },

  {
    lat: 52.3978084,
    lng: 16.8671552,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole nr 83 "ZUCH"',
  },

  {
    lat: 52.2429687,
    lng: 16.857168526483107,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 2 w Mosinie",
  },

  {
    lat: 52.39049801492991,
    lng: 16.968813212323013,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 53 Im. Koszałka Opałka",
  },

  {
    lat: 52.4587796,
    lng: 16.9473509,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Smykoludki s.c.",
  },

  {
    lat: 52.4543169,
    lng: 16.95401821464418,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Parkowe Skrzaty",
  },

  {
    lat: 52.2557842,
    lng: 20.9329989,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 415 im. Akademii Pana Kleksa",
  },

  {
    lat: 52.4047009,
    lng: 16.872345295624584,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 100",
  },

  {
    lat: 52.3750485,
    lng: 16.8794106,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole jak u Mamy",
  },

  {
    lat: 51.79946285,
    lng: 19.406698711135284,
    icon: sensoIcon, type: "senso",
    title: "Fundacja Happy Land",
  },

  {
    lat: 51.4968911,
    lng: 22.85738224845481,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Samorządowe w Ostrowie Lubelskim",
  },

  {
    lat: 52.51651,
    lng: 16.2461235,
    icon: sensoIcon, type: "senso",
    title: "Stowarzyszenie Krzewienia Pedagogicznej I Duchowej Myśli św. Urszuli",
  },

  {
    lat: 52.077079350000005,
    lng: 17.00885339283132,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole z oddziałami integracyjnymi nr 5 "Mali Przyrodnicy',
  },

  {
    lat: 52.3883129,
    lng: 16.8728192,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Publiczne "Fabryka Fantazji" nr 198',
  },

  {
    lat: 52.3977969,
    lng: 16.874379664282642,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 46",
  },

  {
    lat: 52.1896178,
    lng: 20.96166230813442,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Promyk",
  },

  {
    lat: 52.41756855,
    lng: 16.91246750300084,
    icon: sensoIcon, type: "senso",
    title: '"FENOM" Ośrodek Rozwoju i Terapii',
  },

  {
    lat: 52.460773149999994,
    lng: 16.93606555988798,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Niepubliczne "Gumisie"',
  },

  {
    lat: 51.14128805,
    lng: 16.87069788221229,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Nr 66 Bajkolandia",
  },

  {
    lat: 51.09387786317597,
    lng: 16.98949849495275,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Nr 59 "U Krasnala pod narcyzem"',
  },

  {
    lat: 52.4186739,
    lng: 17.079537207232747,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 5 Zielona Półnutka",
  },

  {
    lat: 52.41561383334154,
    lng: 17.080564800664146,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 2 im. Misia Uszatka",
  },

  {
    lat: 52.40692155000001,
    lng: 17.07777550839667,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole nr 3 "Pod Kasztanami"',
  },

  {
    lat: 52.4740757,
    lng: 17.284034588061203,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Wesołe Skrzaty w Pobiedziskach",
  },

  {
    lat: 52.38166,
    lng: 17.07394,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Publiczne Zaczarowana Podkowa",
  },

  {
    lat: 52.27503818028491,
    lng: 20.92219122752439,
    icon: sensoIcon, type: "senso",
    title: "Stowarzyszenie Rodziców i Opiekunów Osób z Zespołem Downa „Bardziej Kochani",
  },

  {
    lat: 52.4743794,
    lng: 17.2949151,
    icon: sensoIcon, type: "senso",
    title: "Od Juniora Do Seniora",
  },

  {
    lat: 52.375531800000005,
    lng: 16.735369728578,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Publiczne Bajkowa Wyspa",
  },

  {
    lat: 52.431846094496,
    lng: 16.93595620665281,
    icon: sensoIcon, type: "senso",
    title: "Instytut Małego Dziecka im. A. Lindgren",
  },

  {
    lat: 51.110256899999996,
    lng: 16.973624958282137,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Niepubliczne we Wrocławskim Parku Technologicznym "Technoludek"',
  },

  {
    lat: 52.27254225,
    lng: 20.9317479499366,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole nr 328 "Akademia pod Czereśnią',
  },

  {
    lat: 51.1008841,
    lng: 17.04668692193043,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole nr 35 "Tęczowy Domek"',
  },

  {
    lat: 52.27553425,
    lng: 21.052086716915824,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 95 im. Króla Maciusia I",
  },

  {
    lat: 52.4147426,
    lng: 17.076809325218342,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 1 Żołędziowe Skrzaty",
  },

  {
    lat: 52.5107291,
    lng: 16.735755192602532,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Ptasie Radio",
  },

  {
    lat: 52.2209712,
    lng: 17.0825031,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Owocowy Zaułek w Błażejewie",
  },

  {
    lat: 52.4166692,
    lng: 16.9206185,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole nr 98 "Słoneczko"',
  },

  {
    lat: 52.0995,
    lng: 20.64215,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Niepubliczne EDU SZKRABY",
  },

  {
    lat: 52.29443375,
    lng: 21.038766202336014,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole nr 91 "Zaczarowany Ogród"',
  },

  {
    lat: 52.390504674586445,
    lng: 16.904080841565115,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczne Przedszkole Akademia Przedszkolaka EDUCANDI",
  },

  {
    lat: 52.47447985,
    lng: 16.87156849078677,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa nr 1 im. Wojciecha Bogusławskiego",
  },

  {
    lat: 52.43255605,
    lng: 16.940879642353817,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 116 ”Kolorowe Nutki”",
  },

  {
    lat: 52.370408600000005,
    lng: 16.933024596301713,
    icon: sensoIcon, type: "senso",
    title: 'Żłobek i Przedszkole "Na Górce"',
  },

  {
    lat: 52.17066,
    lng: 20.82967,
    icon: sensoIcon, type: "senso",
    title: "Gabinet Integracji Sensorycznej Elf",
  },

  {
    lat: 53.1654696,
    lng: 16.7390699,
    icon: sensoIcon, type: "senso",
    title: "Punkt Przedszkolny Mały Książę",
  },

  {
    lat: 52.237612,
    lng: 20.94105366721483,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 273",
  },

  {
    lat: 50.0975629,
    lng: 20.12392262817115,
    icon: sensoIcon, type: "senso",
    title: "Samorządowe Przedszkole nr 63 ”Wesołe Skrzaty”",
  },

  {
    lat: 52.3979504,
    lng: 16.922550975501572,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 87",
  },

  {
    lat: 52.22733095,
    lng: 21.036264859608778,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Integracyjne nr 6",
  },

  {
    lat: 51.5447702,
    lng: 23.5478508,
    icon: sensoIcon, type: "senso",
    title: "Pracownia Rozwoju",
  },

  {
    lat: 52.1996284,
    lng: 18.2446611,
    icon: sensoIcon, type: "senso",
    title: "Fundacja ”W Spektrum Życia”",
  },

  {
    lat: 50.0955473,
    lng: 20.055126835144865,
    icon: sensoIcon, type: "senso",
    title: "Samorządowe Przedszkole nr 111 ”Pod Modrzewiem”",
  },

  {
    lat: 49.4774731,
    lng: 20.052499107293762,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Terapeutyczne "Niebieska Kokardka"',
  },

  {
    lat: 52.22491821517097,
    lng: 20.9936127446272,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 239",
  },

  {
    lat: 51.63413065,
    lng: 15.131803446800474,
    icon: sensoIcon, type: "senso",
    title: "Miejskie Przedszkole nr 8 im. Misia Uszatka - Przyjaciela przyrody",
  },

  {
    lat: 52.213492099999996,
    lng: 15.77706751704629,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczne Przedszkole ”Bąbolandia”",
  },

  {
    lat: 49.85720625,
    lng: 19.350697050704788,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 2 w Andrychowie",
  },

  {
    lat: 52.3902584,
    lng: 16.9172155,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 42 ”Kwiaty Polskie”",
  },

  {
    lat: 52.5759406,
    lng: 19.6399327,
    icon: sensoIcon, type: "senso",
    title:
      "”Niebieski Domek” Terapeutyczny Punkt Przedszkolny Dla Dzieci Z Autyzmem",
  },

  {
    lat: 52.595426849999996,
    lng: 14.645630635472461,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Niepubliczne Nr 1 "Mali Europejczycy"',
  },

  {
    lat: 52.082174550000005,
    lng: 16.630615699999993,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Samorządowe nr 1 im. W. Chotomskiej",
  },

  {
    lat: 49.6026249,
    lng: 19.958192169466024,
    icon: sensoIcon, type: "senso",
    title: "Polskie Stowarzyszenie Na Rzecz Osób Z Niepełnosprawnością Intelektualną -  Koło w Rabce-Zdroju",
  },

  {
    lat: 49.481159500000004,
    lng: 19.697760249495587,
    icon: sensoIcon, type: "senso",
    title: "Polskie Stowarzyszenie Na Rzecz Osób Z Niepełnosprawnością Intelektualną - Koło w Jabłonce",
  },

  {
    lat: 52.2496505,
    lng: 17.09983,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Baśniowa Leśniczówka",
  },

  {
    lat: 52.3668139,
    lng: 17.0237991,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczne Przedszkole ”Baśniowy Świat”",
  },

  {
    lat: 52.3036496,
    lng: 21.158295,
    icon: sensoIcon, type: "senso",
    title: "Zaczarowana Wyspa” Niepubliczny Punkt Przedszkolny i Żłobek",
  },

  {
    lat: 52.4151255,
    lng: 20.742437797600623,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny Nr 1 w Nowym Dworze Mazowieckim",
  },

  {
    lat: 53.08476005,
    lng: 21.584334053399555,
    icon: sensoIcon, type: "senso",
    title: '"Jaś I Małgosia"',
  },

  {
    lat: 51.142638239840664,
    lng: 16.990426406929803,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny Nr 5",
  },

  {
    lat: 52.45148,
    lng: 16.72752,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Publiczne ”Bajkowy Świat” z siedzibą w Sadach",
  },

  {
    lat: 52.62536,
    lng: 16.56388,
    icon: sensoIcon, type: "senso",
    title: "Słoneczko Przedszkole I Żłobek ",
  },

  {
    lat: 52.413025000000005,
    lng: 21.179737498565977,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Niepubliczne ”Akademia Przedszkolaka”",
  },

  {
    lat: 52.32776005,
    lng: 20.94912202244904,
    icon: sensoIcon, type: "senso",
    title: "Fundacja Koocham",
  },

  { lat: 51.1491683,
    lng: 17.1629927,
    icon: sensoIcon, type: "senso",
    title: "Żłobek ALTO",
   },

  {
    lat: 53.18015135,
    lng: 22.071816452306237,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczne Przedszkola",
  },

  {
    lat: 51.274158150000005,
    lng: 15.938119305747735,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczne Przedszkole Terapeutyczne",
  },

  {
    lat: 52.7923901,
    lng: 18.2646501,
    icon: sensoIcon, type: "senso",
    title: 'Niepubliczne Przedszkole Terapeutyczne ”Sowa”',
  },

  {
    lat: 52.3418314,
    lng: 21.1147862,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa Sióstr Rodziny Maryi",
  },

  {
    lat: 51.1538021,
    lng: 16.928434,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny nr 12",
  },

  {
    lat: 51.0727063,
    lng: 16.997084,
    icon: sensoIcon, type: "senso",
    title: "In Harmony Preschool",
  },

  {
    lat: 50.0475087,
    lng: 20.2292587,
    icon: sensoIcon, type: "senso",
    title: "Prywatne Przedszkole Językowo-Artystyczne Słoneczna Kraina",
  },

  { lat: 52.16719,
    lng: 20.80224,
    icon: sensoIcon, type: "senso",
    title: "Fabryka Uśmiechu",
  },

  {
    lat: 50.0925538,
    lng: 20.0762693,
    icon: sensoIcon, type: "senso",
    title: "Publiczne Przedszkole Małe Aniołki",
  },

  {
    lat: 51.6624575,
    lng: 19.492128,
    icon: sensoIcon, type: "senso",
    title: 'Niepubliczne Językowe Przedszkole Integracyjne "Kraina Niedźwiadków"',
  },

  {
    lat: 51.71973937741113,
    lng: 17.85230588686057,
    icon: sensoIcon, type: "senso",
    title: "Publiczne Przedszkole w Słaborowicach",
  },

  {
    lat: 53.16852,
    lng: 16.75469,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczne Przedszkole Terapeutyczne ”Miś Wojtek” w Pile",
  },

  {
    lat: 49.9085036,
    lng: 20.1996034,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Samorządowe w Gdowie",
  },

  {
    lat: 51.6576522,
    lng: 17.78820292151434,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Nr 14 Maluszek",
  },

  {
    lat: 53.17050374931103,
    lng: 22.08301322712942,
    icon: sensoIcon, type: "senso",
    title: "Poradnia Psychologiczno-Pedagogiczna Nr 1 w Łomży",
  },

  {
    lat: 51.22332734103467,
    lng: 22.486004608139382,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Niepubliczne „Mali Giganci III”",
  },

  {
    lat: 51.1105523,
    lng: 17.0047731,
    icon: sensoIcon, type: "senso",
    title: "Autorska Szkoła Podstawowa Jaskółka We Wrocławiu",
  },

  {
    lat: 51.0800718,
    lng: 22.3905019,
    icon: sensoIcon, type: "senso",
    title: "Specjalny Ośrodek Wychowawczy w Załuczu",
  },

  {
    lat: 50.097385349999996,
    lng: 19.958438772495587,
    icon: sensoIcon, type: "senso",
    title: "Niepubliczne Przedszkole Artystyczne ”Marzenie”",
  },

  {
    lat: 51.12026435,
    lng: 15.907419285014985,
    icon: sensoIcon, type: "senso",
    title: 'Niepubliczny Żłobek I Przedszkole ”Pępek Świata”',
  },

  {
    lat: 52.2559303,
    lng: 21.0479842,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno-Przedszkolny nr 10",
  },

  {
    lat: 52.0569358769013,
    lng: 20.847775721704632,
    icon: sensoIcon, type: "senso",
    title: "Gminne Przedszkole w Wólce Kosowskiej",
  },

  {
    lat: 51.64180752242065,
    lng: 17.808548470460728,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 7",
  },

  {
    lat: 51.65376285,
    lng: 17.83299867403001,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Nr 11 "Calineczka"',
  },

  { lat: 51.62653,
    lng: 17.80107,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Nr 2",
  },

  {
    lat: 52.0811868,
    lng: 21.122866682479227,
    icon: sensoIcon, type: "senso",
    title: 'Niepubliczne Przedszkole "Skrzatka Oczatka"',
  },

  {
    lat: 49.89427215,
    lng: 20.606594877074592,
    icon: sensoIcon, type: "senso",
    title: "Centrum Terapii Delfin, lng: Niepubliczny Żłobek Delfinek, lng: Niepubliczne Przedszkole Delfinek",
  },

  { lat: 51.92372605,
    lng: 16.362954771906118,
    icon: sensoIcon, type: "senso",
    title: "Fika",
  },

  { lat: 52.18103,
    lng: 20.80527,
    icon: sensoIcon, type: "senso",
    title: '"Piotruś Pan"',
  },

  {
    lat: 52.17119,
    lng: 20.7871,
    icon: sensoIcon, type: "senso",
    title: 'Niepubliczne Muzyczne Przedszkole "Słoneczko"',
  },

  {
    lat: 52.38964075,
    lng: 16.772456786100722,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Publiczne "Kolorowa Wyspa" w Skórzewie',
  },

  {
    lat: 52.34223,
    lng: 16.79641,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole Publiczne "Zielone Gąski"',
  },

  {
    lat: 51.0639861,
    lng: 16.9796457,
    icon: sensoIcon, type: "senso",
    title: "Educata Gabinet Diagnostyczno - Terapeutyczny",
  },

  {
    lat: 52.3965445,
    lng: 16.8819239,
    icon: sensoIcon, type: "senso",
    title: "Ośrodek Terapii I Wspierania Rozwoju Balans",
  },

  {
    lat: 51.091441,
    lng: 17.0233023,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 108",
  },

  {
    lat: 53.1491014,
    lng: 18.150583618313988,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Niepubliczne Bajka",
  },

  {
    lat: 51.24966163198695,
    lng: 22.556803599475334,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole Niepubliczne „Zacisze”",
  },

  {
    lat: 51.654957350000004,
    lng: 17.795807849030755,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 12 Tęcza",
  },

  {
    lat: 52.4140434,
    lng: 16.893363257316896,
    icon: sensoIcon, type: "senso",
    title: 'Przedszkole nr 71 "Pod Topolą"',
  },

  {
    lat: 52.4121262,
    lng: 16.90120170726933,
    icon: sensoIcon, type: "senso",
    title: "Galileo Niepubliczne Przedszkole",
  },

  {
    lat: 52.415822250000005,
    lng: 16.87473439370402,
    icon: sensoIcon, type: "senso",
    title: "Szkoła Podstawowa Nr 70 im. Mikołaja Kopernika",
  },

  {
    lat: 51.4252218,
    lng: 17.918110031634434,
    icon: sensoIcon, type: "senso",
    title: "Przedszkole nr 6 im. Misia Uszatka",
  },

  {
    lat: 52.4102957262709,
    lng: 16.93906228683543,
    icon: sensoIcon, type: "senso",
    title: "Zespół Szkolno – Przedszkolny nr 4, lng: Przedszkole nr 23",
  },

  {
    lat: 52.4010598,
    lng: 16.9557004,
    icon: sensoIcon, type: "senso",
    title: 'Niepubliczne Przedszkole Językowe "Mały Hipcio"',
  },
  {
    lat: 51.6655235,
    lng: 20.854947,
    icon: eduIcon, type: "edu",
    title: 'Publiczna Szkoła Podstawowa im. Władysława Rosłońca',
  },
  {
    lat: 51.41381980455005,
    lng: 21.09243614027878,
    icon: eduIcon, type: "edu",
    title: 'Publiczna Szkoła Podstawowa im. Teresy Grodzińskiej',
  },
  {
    lat: 50.267105400000005,
    lng: 19.466096617461744,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa nr 1 w Bukownie',
  },
  {
    lat: 52.0289979,
    lng: 20.80134796597026,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa im. bł. ks. B. Markiewicza w Woli Krakowiańskiej',
  },
  {
    lat: 52.12273195,
    lng: 18.239124056631773,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa im. Bolesława Prusa w Liścu Wielkim',
  },
  {
    lat: 52.44046716119681,
    lng: 20.77750195503896,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa im. Józefa Wybickiego w Janówku Pierwszym',
  },
  {
    lat: 50.8926331,
    lng: 17.1337642,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa im. Jana Pawła II w Domaniowie',
  },
  {
    lat: 52.3408321,
    lng: 20.02291274028451,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa im. Marszałka Józefa Piłsudskiego w Iłowie',
  },
  {
    lat: 52.22542095,
    lng: 20.24115368764854,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa nr 1 im. Jana Pawła II',
  },
  {
    lat: 52.2263171,
    lng: 16.36398668520281,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa nr 2 im. Powstańców Wielkopolskich w Grodzisku Wielkopolskim',
  },
  {
    lat: 50.2812063,
    lng: 19.4293309,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa nr 2 w Bukownie',
  },
  {
    lat: 52.23335145,
    lng: 20.238738942522907,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa nr 4 z oddziałami integracyjnymi im. Janusza Korczaka',
  },
  {
    lat: 53.1221882,
    lng: 17.290545406729017,
    icon: eduIcon, type: "edu",
    title: 'Uczniowski Klub Sportowy "ORZEŁ" przy Szkole Podstawowej im. Stanisława Staszica w Osieku nad Notecią',
  },
  {
    lat: 50.01042775,
    lng: 18.470400534535912,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkolno - Przedszkolny nr 7',
  },
  {
    lat: 50.5378285,
    lng: 16.896433520735375,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkolno - Przedszkolny nr 2 w Kamieńcu Ząbkowickim',
  },
  {
    lat: 50.023250149999996,
    lng: 18.466417216503785,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkolno - Przedszkolny nr 3 ul. Marii Curie Skłodowskiej',
  },
  {
    lat: 54.647327849950315,
    lng: 18.371308035277057,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkolno - Przedszkolny w Połchowie',
  },
  {
    lat: 50.0134307,
    lng: 18.4365668,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkolno - Przedszkolny nr 4',
  },
  {
    lat: 52.4198198,
    lng: 20.68268380733906,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkolno - Przedszkolny w Kazuniu Nowym',
  },
  {
    lat: 53.82189245,
    lng: 14.977120886263977,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkół Publicznych, Szkoła Podstawowa im. Bolesława Chrobrego Golczewo',
  },
  {
    lat: 50.583401,
    lng: 19.7817458,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkół Społecznych im. Jana Pawła II w Rokitnie',
  },
  {
    lat: 51.98885165,
    lng: 20.833842622296523,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkół Tarczyn',
  },
  {
    lat: 50.010854949999995,
    lng: 18.451325613617275,
    icon: sensoIcon, type: "senso",
    title: 'Publiczne Przedszkole nr 15',
  },
  {
    lat: 50.010854949999995,
    lng: 18.451325613617275,
    icon: sensoIcon, type: "senso",
    title: 'Publiczne Przedszkole nr 15',
  },
  {
    lat: 52.494686692479775,
    lng: 20.61413908919999,
    icon: sensoIcon, type: "senso",
    title: 'Szkoła Podstawowa im. Fryderyka Chopina w Wojszczycach',
  },
  {
    lat: 50.8926331,
    lng: 17.1337642,
    icon: sensoIcon, type: "senso",
    title: 'Szkoła Podstawowa im. Jana Pawła II w Domaniowie',
  },
  {
    lat: 53.01951,
    lng: 20.88189,
    icon: sensoIcon, type: "senso",
    title: 'Szkoła Podstawowa nr 2 im. Henryka Sienkiewicza',
  },
  {
    lat: 52.308939,
    lng: 21.8586661,
    icon: eduIcon, type: "edu",
    title: 'Zespół Szkół im. Adama Mickiewicza w Wierzbnie',
  },
  {
    lat: 50.7574739,
    lng: 16.935341979842356,
    icon: eduIcon, type: "edu",
    title: 'Szkoła Podstawowa im. Bohaterów Armii Krajowej w Prusach',
  },
  { lat: 50.0704209, lng: 19.447097, icon: eduIcon, type: "edu", title: "Urząd Gminy Babice", },
{ lat: 50.0728263, lng: 19.444306281694562, icon: eduIcon, type: "edu", title: "Zespół Szkół w Babicach", },
{ lat: 50.045984700000005, lng: 19.448126643765505, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. ks. Jana Twardowskiego", },
{ lat: 50.0508074, lng: 19.38029325919963, icon: eduIcon, type: "edu", title: "Zespół Szkół w Mętkowie", },
{ lat: 51.2629882, lng: 18.0078466, icon: sensoIcon, type: "senso", title: "Zespół Szkół im. Powstańców Wielkopolskich", },
{ lat: 51.2397525, lng: 18.04125501847826, icon: sensoIcon, type: "senso", title: "Zespół Szkół w Słupii", },
{ lat: 51.226115050000004, lng: 17.983499563154687, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. Jana Pawła II", },
{ lat: 51.2672091, lng: 18.003258076272623, icon: sensoIcon, type: "senso", title: "Urząd Gminy w Baranowie", },
{ lat: 52.54171995, lng: 16.946606302508144, icon: sensoIcon, type: "senso", title: "Zespół Szkół w Biedrusku", },
{ lat: 52.54171995, lng: 16.946606302508144, icon: sensoIcon, type: "senso", title: "Zespół Szkół w Biedrusku", },
{ lat: 51.69787, lng: 16.79072, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Arkadego Fiedlera", },
{ lat: 51.7064537, lng: 16.748765845483092, icon: eduIcon, type: "edu", title: "Miasto i Gmina Bojanowo", },
{ lat: 51.727515600000004, lng: 16.79716508003058, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. J. Brzechwy", },
{ lat: 52.545207649999995, lng: 16.990573992259883, icon: sensoIcon, type: "senso", title: "Akademia Odkrywców w Bolechowie", },
{ lat: 50.884667449999995, lng: 16.991926309181338, icon: sensoIcon, type: "senso", title: "Publiczna Szkoła Podstawowa im. Marii Konopnickiej w Zielenicach ", },
{ lat: 50.8848862, lng: 16.994826501691442, icon: sensoIcon, type: "senso", title: "Publiczna Szkoła Podstawowa im.Wincentego Witosa w Borku Strzelińskim", },
{ lat: 52.11348185, lng: 16.927960535603667, icon: eduIcon, type: "edu", title: "Zespół Szkolno- Przedszkolny im. gen. J. Wybickiego", },
{ lat: 52.144201800000005, lng: 16.890118122479045, icon: eduIcon, type: "edu", title: "Zespół Szkolno- Przedszkolny im. Hymnu Narodowego", },
{ lat: 52.16725285, lng: 16.812501118626408, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. gen. Dezyderego Chłapowskiego", },
{ lat: 52.11348185, lng: 16.927960535603667, icon: sensoIcon, type: "senso", title: "Zespół Szkolno- Przedszkolny im. gen. J. Wybickiego", },
{ lat: 52.144201800000005, lng: 16.890118122479045, icon: sensoIcon, type: "senso", title: "Zespół Szkolno- Przedszkolny im. Hymnu Narodowego", },
{ lat: 52.16725285, lng: 16.812501118626408, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. gen. Dezyderego Chłapowskiego", },
{ lat: 53.25204305, lng: 19.400749227434936, icon: eduIcon, type: "edu", title: "Urząd Gminy Brodnica", },
{ lat: 52.3544727, lng: 16.517912952222268, icon: sensoIcon, type: "senso", title: "Przedszkole im. Krasnala Hałabały", },
{ lat: 52.35662, lng: 16.52259, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. Bohaterów Bukowskich", },
{ lat: 52.35662, lng: 16.52259, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Bohaterów Bukowskich", },
{ lat: 52.30324775, lng: 16.50529615921103, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. dr. L. Siudy w Szewcach", },
{ lat: 52.3832345, lng: 16.6055319, icon: eduIcon, type: "edu", title: "Zespół Szkolno - Przedszkolny im. Dr. Wandy Błeńskiej w Niepruszewie", },
{ lat: 52.3310568, lng: 16.540185, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im.O. Ignacego Cieślaka", },
{ lat: 52.101840949999996, lng: 21.396615669883047, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Mikołaja Kopernika w Ostrowie", },
{ lat: 52.556804400000004, lng: 16.84669813387405, icon: sensoIcon, type: "senso", title: "Przedszkole w Chludowie", },
{ lat: 52.5556, lng: 16.84555, icon: sensoIcon, type: "senso", title: "Zespół Szkół im. o. Mariana Żelazka w Chludowie", },
{ lat: 50.61641, lng: 20.75204, icon: sensoIcon, type: "senso", title: "Przedszkole w Chmielniku z Filią w Suchowoli", },
{ lat: 52.449225150000004, lng: 16.998919933547704, icon: sensoIcon, type: "senso", title: "Gminne Przedszkole nr 1 im. Krasnala Hałabały", },
{ lat: 52.4466421, lng: 16.993304817514968, icon: sensoIcon, type: "senso", title: "Gminne Przedszkole nr 1 im. Bajkowe Ludki", },
{ lat: 52.478273900000005, lng: 16.984060500000002, icon: sensoIcon, type: "senso", title: "Gminne Przedszkole im. Dzieci z Leszczynowej Górki", },
{ lat: 52.5113468, lng: 16.9835757, icon: sensoIcon, type: "senso", title: "Gminne Przedszkole w Owińskach", },
{ lat: 53.53845165, lng: 17.388524193106388, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Starym Gronowie", },
{ lat: 53.5662, lng:17.29855, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa", },
{ lat: 53.53845165, lng:17.388524193106388, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa", },
{ lat: 52.73761, lng:14.70059, icon: sensoIcon, type: "senso", title: "Przedszkola nr 1 im. Czarodziejska Kraina", },
{ lat: 52.7356862, lng:14.697841263383197, icon: sensoIcon, type: "senso", title: "Przedszkola nr 2 im. S. Modrzejewskiej", },
{ lat: 50.8926331, lng: 17.1337642, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Jana Pawła II w Domaniowie", },
{ lat: 52.3558383, lng: 16.6723338, icon: eduIcon, type: "edu", title: "Zespół Szkolno-Przedszkolny w Dopiewie", },
{ lat: 52.3967639, lng: 16.6383218, icon: eduIcon, type: "edu", title: "Zespół Szkolno-Przedszkolny im. Powstańców Wielkopolskich", },
{ lat: 52.3543379, lng: 16.6781486, icon: eduIcon, type: "edu", title: "Urząd Gminy Dopiewo", },
{ lat: 52.3558383, lng:16.6723338, icon: sensoIcon, type: "senso", title: "Zespół Szkolno-Przedszkolny", },
{ lat: 54.22503675, lng: 19.348047785857794, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Państwowej Straży Pożarnej w Nowakowie", },
{ lat: 54.140673050000004, lng: 19.46179207592491, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Gronowie Górnym", },
{ lat: 51.8965728, lng: 21.5951724, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 2", },
{ lat: 52.903414, lng:21.562253, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. św. Stanisława Kostki", },
{ lat: 54.23943, lng:18.75308, icon: sensoIcon, type: "senso", title: "Zespół Szkolno-Przedszkolny w Grabinach-Zameczku", },
{ lat: 51.51452, lng:21.12363, icon: sensoIcon, type: "senso", title: "Publiczna Szkoła Podstawowa", },
{ lat: 52.136083, lng: 21.2406242192639, icon: eduIcon, type: "edu", title: "Zespół Szkolno - Przedszkolny nr 1", },
{ lat: 51.04170415, lng: 21.83337093108541, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa", },
{ lat: 52.06306, lng: 21.48469, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Armii Krajowej", },
{ lat: 52.01782275, lng: 21.476013105825245, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Kątach", },
{ lat: 52.04580345, lng: 21.44506617796791, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. ks. Jana Twardowskiego ", },
{ lat: 52.10559205, lng: 21.49394428066328, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Kawalerów Orderu Usmiechu", },
{ lat: 50.7574739, lng: 16.935341979842356, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Bohaterów Armii Krajowej w Prusach", },
{ lat: 52.0891506, lng: 21.1230641, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 4 im.Jana Mejstera w Słomczynie", },
{ lat: 52.0793892, lng: 21.1230687, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 2", },
{ lat: 52.39739175, lng: 17.22840693776997, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Arkadego Fiedlera w Gułtowach", },
{ lat: 52.38181, lng: 17.16167, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Pauliny i Augusta Wilkońskich w Siekierkach", },
{ lat: 52.38181, lng: 17.16167, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. Pauliny i Augusta Wilkońskich w Siekierkach Wielkich", },
{ lat: 51.918290400000004, lng: 17.645780207838136, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Marii Konopnickiej w Kotlinie", },
{ lat: 51.58110945, lng: 21.548193970793562, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 1 im. Urszuli Kochanowskiej", },
{ lat: 51.585619750000006, lng: 21.54417757961965, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 2 im. Króla Zygmunta Starego", },
{ lat: 51.58980335, lng: 21.53681021001445, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 3 z im. Jana Kochanowskiego w Kozienicach", },
{ lat: 51.5787434, lng: 21.557966550286434, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 4 im. Jana Pawła II w Kozienicach", },
{ lat: 51.658197400000006, lng: 21.48592766753213, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. bp. Jana Chrapka w Świerżach Górnych", },
{ lat: 51.5851001, lng: 21.6134378, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Wólce Tyrzyńskiej", },
{ lat: 51.5885562, lng: 21.54711479681718, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. chor. Jana Szymańskiego w Ryczywole", },
{ lat: 51.566409050000004, lng: 21.59915327379202, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Janikowie", },
{ lat: 51.663132, lng: 21.3922823, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Stefana Czarnieckiego w Woli Chodkowskiej", },
{ lat: 51.5889172, lng: 21.555062901335866, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Stanisławicach", },
{ lat: 50.103547899999995, lng: 21.485004313916374, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Brzeźnicy", },
{ lat: 54.3770364, lng: 19.43045560246698, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Janusza Korczaka", },
{ lat: 52.55194, lng: 16.08478, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Tadeusza Kościuszki w Kwilczu", },
{ lat: 52.5281737, lng: 16.183268, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Konstytucji 3 Maja w Luboszu", },
{ lat: 51.1435778, lng: 16.2451088, icon: sensoIcon, type: "senso", title: "Zespół Szkolno-Przedszkolny", },
{ lat: 50.940510450000005, lng: 15.2969727419616, icon: sensoIcon, type: "senso", title: "Zespołu Szkolno - Przedszkolnego", },
{ lat: 51.0169501, lng: 15.25709456431629, icon: sensoIcon, type: "senso", title: "Miejsko-Gminne Przedszkole im. Kubusia Puchatka w Leśnej", },
{ lat: 52.5281737, lng: 16.183268, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Konstytucji 3 Maja w Luboszu", },
{ lat: 49.731901949999994, lng: 19.136964698150166, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 2 im. Wł. Jagiełły", },
{ lat: 49.7003768, lng: 19.159129601571337, icon: eduIcon, type: "edu", title: "Zespół Szkolno - Przedszkolny w Pietrzykowicach", },
{ lat: 53.80355, lng: 20.0889, icon: eduIcon, type: "edu", title: "Zespół Szkolno - Przedszkolny w Łukcie", },
{ lat: 53.09773, lng: 17.00457, icon: sensoIcon, type: "senso", title: "Publiczne Przedszkole", },
{ lat: 52.550517, lng: 18.4939071, icon: sensoIcon, type: "senso", title: "Przedszkole Samorządowe", },
{ lat: 51.68845, lng: 17.07472, icon: eduIcon, type: "edu", title: "Zespół Szkół w Dłoni", },
{ lat: 51.65743085, lng: 17.038035636502933, icon: eduIcon, type: "edu", title: "Zespół Szkolno - Przedszkolny w Konarach", },
{ lat: 50.66390335, lng: 16.188711180753643, icon: sensoIcon, type: "senso", title: "Przedszkole Miejskie w Mieroszowie", },
{ lat: 51.53455625, lng: 17.975911622508605, icon: sensoIcon, type: "senso", title: "Przedszkole w Mikstacie", },
{ lat: 53.3037566, lng: 21.2868073, icon: eduIcon, type: "edu", title: "Publiczna Szkoła Podstawowa", },
{ lat: 53.4307432, lng: 21.4617859, icon: eduIcon, type: "edu", title: "Publiczna Szkoła Podstawowa", },
{ lat: 53.3480269, lng: 21.3792499, icon: eduIcon, type: "edu", title: "Publiczna Szkoła Podstawowa", },
{ lat: 53.3852912, lng: 21.33997481642059, icon: eduIcon, type: "edu", title: "Urząd Miejski w Myszyńcu", },
{ lat: 50.87081, lng: 21.12059, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Starej Słupi", },
{ lat: 50.88351175, lng: 21.04073766596605, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Mirocicachi", },
{ lat: 50.8951, lng: 21.12741, icon: eduIcon, type: "edu", title: "Szkola Podstawowa w Pokrzywiancei", },
{ lat: 50.84436, lng: 21.11639, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Jeleniowie", },
{ lat: 50.89385, lng: 21.02687, icon: eduIcon, type: "edu", title: "\Publiczna Szkoła Podstawowa w Jeziorku", },
{ lat: 50.87081, lng: 21.12059, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa", },
{ lat: 50.88351175, lng: 21.04073766596605, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa", },
{ lat: 50.8951, lng: 21.12741, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa", },
{ lat: 50.84436, lng: 21.11639, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa", },
{ lat: 50.89385, lng: 21.02687, icon: sensoIcon, type: "senso", title: "Publiczna Szkoła Podstawowa", },
{ lat: 51.7965652, lng: 15.660331251551485, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Lubieszowie", },
{ lat: 51.805739450000004, lng: 15.722481167241973, icon: eduIcon, type: "edu", title: "Zespół Edukacyjny w Przyborowie", },
{ lat: 51.7109237, lng: 17.98927831682161, icon: sensoIcon, type: "senso", title: "Przedszkole nr 1 im. Jarzębinka", },
{ lat: 50.5194563, lng: 21.4485307, icon: eduIcon, type: "edu", title: "Zespół Szkół im. Jana Pawła II w Osieku", },
{ lat: 52.626561249999995, lng: 16.445103717392385, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Jana Ostroroga w Ostrorogu", },
{ lat: 50.6811914, lng: 18.212732560515704, icon: sensoIcon, type: "senso", title: "Przedszkole nr 1 w Ozimku", },
{ lat: 50.678657799999996, lng: 18.220342648257077, icon: sensoIcon, type: "senso", title: "Przedszkole nr 2 w Ozimku", },
{ lat: 50.6741239, lng: 18.255222319303034, icon: sensoIcon, type: "senso", title: "Przedszkole nr 5 w Krasiejowie", },
{ lat: 50.68018, lng: 18.20982, icon: eduIcon, type: "edu", title: "Urząd Miasta i Gminy Ozimek", },
{ lat: 51.9877312, lng: 19.3761993, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. 17 Wielkopolskiej Dywizji Piechoty w Modlnej", },
{ lat: 51.758125899999996, lng: 17.11384520958348, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. Jana Pawła II", },
{ lat: 52.06472875, lng: 21.0288658431436, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa Nr 1 im. Józefa Piłsudskiego w Piasecznie", },
{ lat: 50.0831429, lng: 19.443481899999995, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa z Oddziałami Integracyjnymi im. Powstańców Wielkopolskich", },
{ lat: 51.8486675, lng: 17.0706667, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa z Oddziałami Integracyjnymi im. Powstańców Wielkopolskich w Bodzewie ", },
{ lat: 51.8876575, lng: 17.0629183, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa z Odzdziałami Integracyjnymi im. Mikołaja Kopernika", },
{ lat: 50.0831429, lng: 19.443481899999995, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa z Oddziałami Integracyjnymi im. Powstańców Wielkopolskich", },
{ lat: 51.8876575, lng: 17.0629183, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa z Oddziałami Integracyjnymi im. Mikołaja Kopernika", },
{ lat: 51.86505, lng: 17.15893, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Bł. Edmunda Bojanowskiego Szelejewo Pierwsze", },
{ lat: 52.7549137, lng: 17.4887341, icon: sensoIcon, type: "senso", title: "Przedszkole z Oddziałami Integracyjnymi  w Piaskach", },
{ lat: 52.5512522, lng: 19.688468332515043, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 16 im. Mikołaja Kopernika w Płocku", },
{ lat: 52.559177399999996, lng: 19.673702811875692, icon: sensoIcon, type: "senso", title: "Przedszkole z Oddziałami Integracyjnymi nr 3", },
{ lat: 52.5595704, lng: 19.671526162075978, icon: sensoIcon, type: "senso", title: "Miejskie Przedszkole nr 19", },
{ lat: 52.5252624, lng: 19.681050907973425, icon: sensoIcon, type: "senso", title: "Miejskie Przedszkole nr 13", },
{ lat: 52.5522005, lng: 19.698146264001558, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 11", },
{ lat: 52.54978225, lng: 19.7088786, icon: sensoIcon, type: "senso", title: "Miejskie Przedszkole nr 21", },
{ lat: 52.5453658, lng: 19.723209, icon: sensoIcon, type: "senso", title: "Miejskie Przedszkole nr 5", },
{ lat: 52.5160041, lng: 19.762972880541415, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 20 im. Wł. Broniewskiego", },
{ lat: 52.5406994, lng: 19.75846354174348, icon: eduIcon, type: "edu", title: " Zespół Szkół nr 5", },
{ lat: 52.51308, lng: 16.25795, icon: sensoIcon, type: "senso", title: "Przedszkole Miś w Pniewach", },
{ lat: 49.5707575, lng: 20.51984559321821, icon: eduIcon, type: "edu", title: " Szkoła Podstawowa im. Mieczysława Wieczorka w Olszanie", },
{ lat: 51.4047912, lng: 19.689434502134326, icon: eduIcon, type: "edu", title: "Urząd Miasta Piotrkowa Trybunalskiego", },
{ lat: 54.26181875, lng: 18.64990165003843, icon: sensoIcon, type: "senso", title: "Zespół Szkół Ogólnokształcących nr 1", },
{ lat: 54.26181875, lng: 18.64990165003843, icon: eduIcon, type: "edu", title: "Zespół Szkół Ogólnokształcących nr 1", },
{ lat: 52.70435085, lng: 21.075796429328832, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 3", },
{ lat: 52.2742289, lng: 16.8585863, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 2 im. Powstańców Wielkopolskich", },
{ lat: 52.2890844, lng: 16.854065482311157, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 1", },
{ lat: 52.2890844, lng: 16.854065482311157, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 1", },
{ lat: 52.277116750000005, lng: 16.852080181645633, icon: sensoIcon, type: "senso", title: "Przedszkole im. Leśni Przyjaciele", },
{ lat: 52.28861835, lng: 16.852991530027406, icon: sensoIcon, type: "senso", title: "Przedszkole im. Pracowite Pszczółki", },
{ lat: 52.2890844, lng: 16.854065482311157, icon: sensoIcon, type: "senso", title: "Miejski Ośrodek Pomocy Społecznej", },
{ lat: 52.2742289, lng: 16.8585863, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 2 im. Powstańców Wielkopolskich w Puszczykowie", },
{ lat: 52.3260856, lng: 16.8456436, icon: sensoIcon, type: "senso", title: "Przedszkole Julka i Miś", },
{ lat: 51.7743087, lng: 17.503549573004292, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa w Rozdrażewie", },
{ lat: 51.8123, lng: 17.56648, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 2 im. Powstańców Wielkopolskich w Nowej Wsi", },
{ lat: 51.660087250000004, lng: 19.49606464879166, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Jana Długosza  w Rzgowie", },
{ lat: 51.5797149, lng: 18.7134958, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Charłupi Małej", },
{ lat: 51.5708187, lng: 18.7466434, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Charłupi Małej", },
{ lat: 51.5859827, lng: 18.714865367424277, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa", },
{ lat: 52.3562205, lng: 14.5660276, icon: eduIcon, type: "edu", title: "Gmina Słubice", },
{ lat: 52.2472066, lng: 20.8183065, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa", },
{ lat: 52.251843699999995, lng: 20.73891278816864, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa", },
{ lat: 52.25397575, lng: 20.841170699491578, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. H. Sienkiewicza w Starych Babicach", },
{ lat: 52.25397575, lng: 20.841170699491578, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. H. Sienkiewicza", },
{ lat: 52.2495682, lng: 20.8314437, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa", },
{ lat: 51.1332092, lng: 20.6524254, icon: eduIcon, type: "edu", title: "Publiczna Szkoła Podstawowa nr 1 im Kornela Makuszyńskiego", },
{ lat: 51.1332092, lng: 20.6524254, icon: sensoIcon, type: "senso", title: "Publiczna Szkoła Podstawowa nr 1 im Kornela Makuszyńskiego", },
{ lat: 54.2074834, lng: 18.763808299782525, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Obrońców Westerplatte w Suchym Dębie", },
{ lat: 54.2074834, lng: 18.763808299782525, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Księdza Prałata Józefa Waląga", },
{ lat: 52.4748269, lng: 16.8710159, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 1", },
{ lat: 52.48013505, lng: 16.870331092784745, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 2", },
{ lat: 52.4752, lng: 16.86725, icon: sensoIcon, type: "senso", title: "Przedszkole Leśnych Ludków", },
{ lat: 52.09278405, lng: 15.621153949985594, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 1 w Sulechowie", },
{ lat: 52.063150300000004, lng: 15.432806871663855, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Brodach", },
{ lat: 52.1250638, lng: 15.59177319278377, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa w Kalsku", },
{ lat: 52.0857685, lng: 15.628080010040547, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa w Kijach", },
{ lat: 50.70473765, lng: 18.15193561861393, icon: sensoIcon, type: "senso", title: "Przedszkole nr 6 w Szczedrzyk", },
{ lat: 50.8605776, lng: 16.48411271111675, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 1", },
{ lat: 50.84410485, lng: 16.497090540848227, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 6", },
{ lat: 50.8444118, lng: 16.4924217, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 105", },
{ lat: 50.8345588, lng: 16.4765733, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 8 im. Kawalerów Orderu Uśmiechu", },
{ lat: 50.8454979, lng: 16.4667429, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 4 im. H. Sienkiewicza", },
{ lat: 50.8374901, lng: 16.4776547, icon: eduIcon, type: "edu", title: "Urząd Gminy w Świdnicy", },
{ lat: 52.439026049999995, lng: 16.592723468103493, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. Janusza Korczaka w Ceradzu Kościelnym", },
{ lat: 52.21179, lng: 20.43377, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Jana Pawła II w Paprotni", },
{ lat: 52.1610203, lng: 20.374433491756527, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Mikołaja Kopernika w Szymanowie", },
{ lat: 52.197430749999995, lng: 20.332215854402385, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Fryderyka Chopina w Budkach Piaseckich", },
{ lat: 49.7401943, lng: 19.866628941966027, icon: eduIcon, type: "edu", title: "Grupa Przyjaciół Historii Ziemi Tokarskiej", },
{ lat: 51.855656350000004, lng: 16.496874909658533, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Marii Skłodowskiej - Curie", },
{ lat: 51.8546247, lng: 16.494810335913115, icon: eduIcon, type: "edu", title: "Urząd Gminy Święciechowa", },
{ lat: 54.6079258, lng: 18.8017851, icon: eduIcon, type: "edu", title: "Zespół Szkół Ogólnokształcących", },
{ lat: 54.604938399999995, lng: 18.80250834061735, icon: eduIcon, type: "edu", title: "Urząd Miasta Helu", },
{ lat: 52.010538600000004, lng: 21.55290865628019, icon: eduIcon, type: "edu", title: " Publiczna Szkoła Podstawowa", },
{ lat: 51.960365, lng: 21.525540641449993, icon: eduIcon, type: "edu", title: "Urząd Miasta i Gminy Pilawa", },
{ lat: 50.46366635, lng: 19.652350559836062, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 1", },
{ lat: 50.5070911, lng: 19.6575405, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 3", },
{ lat: 50.4755294, lng: 19.7214455, icon: eduIcon, type: "edu", title: "Zespół Szkolno - Przedszkolny ", },
{ lat: 50.4693881, lng: 19.66804476025768, icon: eduIcon, type: "edu", title: "Urząd Miasta i Gminy Pilica", },
{ lat: 51.309248, lng: 17.7221937, icon: eduIcon, type: "edu", title: "Urząd Gminy Święciechowa", },
{ lat: 50.46366635, lng: 19.652350559836062, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 1", },
{ lat: 50.5070911, lng: 19.6575405, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 3", },
{ lat: 50.4755294, lng: 19.7214455, icon: sensoIcon, type: "senso", title: "Zespół Szkolno - Przedszkolny", },
{ lat: 51.0967883, lng: 19.4592855, icon: eduIcon, type: "edu", title: "Publiczna Szkola Podstawowa nr 5 im.Mikolaja Kopernika", },
{ lat: 51.0641769, lng: 19.4477037, icon: eduIcon, type: "edu", title: "Urząd Miasta Radomska", },
{ lat: 49.741531800000004, lng: 18.63668733263097, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 5 z Oddziałami Integracyjnymi", },
{ lat: 49.74815995, lng: 18.633210373623065, icon: eduIcon, type: "edu", title: "Urząd Miejski w Cieszynie", },
{ lat: 50.9729319, lng: 18.208269592066983, icon: eduIcon, type: "edu", title: "Publiczna Szkoła Podstawowa Nr 1", },
{ lat: 50.9774363, lng: 18.216286851050203, icon: eduIcon, type: "edu", title: "Publiczna Szkoła Podstawowa Nr 2", },
{ lat: 52.2213465, lng: 20.9160774, icon: sensoIcon, type: "senso", title: "Publiczne Przedszkole Europejskie I", },
{ lat: 52.1848944, lng: 20.991065, icon: sensoIcon, type: "senso", title: "Publiczne Przedszkole Europejskie IV", },
{ lat: 52.34768135, lng: 21.055304143766634, icon: sensoIcon, type: "senso", title: "Publiczne Przedszkole Europejskie III", },
{ lat: 52.15850625, lng: 21.199573111911583, icon: sensoIcon, type: "senso", title: "Publiczne Przedszkole Europejskie II", },
{ lat: 52.34768135, lng: 21.055304143766634, icon: sensoIcon, type: "senso", title: "Publiczne Przedszkole Europejskie III", },
{ lat: 54.1680676, lng: 19.88036178870661, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa", },
{ lat: 54.14293995, lng: 19.789713497676665, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Mikołaja Kopernika w Słobitach Słobit", },
{ lat: 50.0136725, lng: 18.44713369376325, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa nr 28", },
{ lat: 50.029047649999995, lng: 18.436666494641834, icon: sensoIcon, type: "senso", title: "Zespół Szkolno-Przedszkolny nr 5", },
{ lat: 50.0136725, lng: 18.44713369376325, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 28", },
{ lat: 50.029047649999995, lng: 18.436666494641834, icon: eduIcon, type: "edu", title: "Zespół Szkolno-Przedszkolny nr 5", },
{ lat: 50.00085831778515, lng: 18.438338420267858, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 2", },
{ lat: 50.0017021, lng: 18.459521802358267, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 1 im. Marii Skłodowskiej-Curie", },
{ lat: 50.010700400000005, lng: 18.40997793598485, icon: eduIcon, type: "edu", title: "Zespół Szkolno-Przedszkolny nr 6", },
{ lat: 50.00085831778515, lng: 18.438338420267858, icon: sensoIcon, type: "senso", title:  "Szkoła Podstawowa nr 2", },
{ lat: 50.0017021, lng: 18.459521802358267, icon: sensoIcon, type: "senso", title:  "Szkoła Podstawowa nr 1 im. Marii Skłodowskiej-Curie", },
{ lat: 50.010700400000005, lng: 18.40997793598485, icon: sensoIcon, type: "senso", title:  "Zespół Szkolno-Przedszkolny nr 6", },
{ lat: 50.3675556, lng: 19.0158614, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 1", },
{ lat: 53.1619346, lng: 17.26353357275285, icon: eduIcon, type: "edu", title: "Uczniowski Klub Sportowy 'Top-Spin'", },
{ lat: 53.1149036, lng: 19.0158172977099614, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Stanisława Staszica", },
{ lat: 50.29621315, lng: 18.791380657396736, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 3 im. Bohaterów Monte Cassino", },
{ lat: 50.316805099999996, lng: 18.781212616809974, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. A. Mickiewicza nr. 14", },
{ lat: 50.28653945, lng: 18.814046734594836, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 16", },
{ lat: 51.8487298, lng: 19.4148216, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 23", },
{ lat: 50.28653945, lng: 18.814046734594836, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 5 w Zgierzu", },
{ lat: 51.851142100000004, lng: 19.42179154181207, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 6 im. Jana Kochanowskiego w Zgierzu", },
{ lat: 51.86030265, lng: 19.39016110431661, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 8 w Zgierzu", },
{ lat: 51.851142100000004, lng: 19.42179154181207, icon: sensoIcon, type: "senso", title:  "Szkoła Podstawowa Nr 6 im. Jana Kochanowskiego w Zgierzu", },
{ lat: 51.86030265, lng: 19.39016110431661, icon: sensoIcon, type: "senso", title:  "Szkoła Podstawowa Nr 8 w Zgierzu", },
{ lat: 52.01064595, lng: 20.734465790664018, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Stefanii Dziewulskiej", },
{ lat: 52.02911374999999, lng: 20.66779601560725, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Kawalerów Orderu Uśmiechu", },
{ lat: 51.04705475,  lng: 23.89088874166883, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Dubience",  },
{ lat: 51.0467221,  lng: 23.8908242, icon: eduIcon, type: "edu", title: "Gmina Dubienka",  },
{ lat: 54.0037144,  lng: 18.828572038212243, icon: eduIcon, type: "edu", title: "Urząd Gminy w Subkowach",  },
{ lat: 54.00094075,  lng: 18.769113587213518, icon: eduIcon, type: "edu", title: "Urząd Gminy w Subkowach",  },
{ lat: 52.0573026,  lng: 20.4384877, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa Nr 2 im. Marii Konopnickiej",  },
{ lat: 52.05669865,  lng: 20.43830815358418, icon: eduIcon, type: "edu", title: "Urząd Miasta Żyrardowa",  },
{ lat: 52.04320185,  lng: 19.41910087865861, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Orła Białego w Czernikowie",  },
{ lat: 52.069798000000006,  lng: 19.481246911085005, icon: eduIcon, type: "edu", title: "Urząd Miejski w Piątku",  },
{ lat: 52.9893047,  lng: 22.241957529853245, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 4 im. Władysława Broniewskiego",  },
{ lat: 52.975514,  lng: 22.247360092153286, icon: eduIcon, type: "edu", title: "Urząd Miasta Zambrów",  },
{ lat: 50.9060492,  lng: 16.8805069, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Pustkowie Wilczkowskim",  },
{ lat: 52.352441999999996,  lng: 17.215249781174254, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Wincentego Witosa w Czerlejnie",  },
{ lat: 52.352441999999996,  lng: 17.215249781174254, icon: eduIcon, type: "edu", title: "Zespół Szkolno-Przedszkolny w Zarzeczu",  },
{ lat: 53.4447683,  lng: 23.08996153455407, icon: eduIcon, type: "edu", title: " Urząd Gminy Korycin",  },
{ lat: 51.82517585,  lng: 16.483993722874647, icon: eduIcon, type: "edu", title: " Szkoła Podstawowa w Lasociach",  },
{ lat: 51.8546247,  lng: 16.494810335913115, icon: eduIcon, type: "edu", title: " Gmina Święciechowa",  },
{ lat: 52.3019781,  lng: 16.96587541839152, icon: eduIcon, type: "edu", title: " Szkoły Podstawowej im. Kawalerów Orderu Uśmiechu w Daszewicach",  },
{ lat: 50.2066775,  lng: 23.1089757, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. Marii Moralewicz w Dachnowie",  },
{ lat: 50.24228105,  lng: 23.144876101985552, icon: eduIcon, type: "edu", title: "Zespół Szkolno-Przedszkolny w Cieszanowie",  },
{ lat: 50.2457688,  lng: 23.13168983922037, icon: eduIcon, type: "edu", title: "Urząd Miasta i Gminy Cieszanów",  },
{ lat: 52.09118805,  lng: 16.655949803825628, icon: eduIcon, type: "edu", title: "Zespół Szkół  w Racocie",  },
{ lat: 49.46712035,  lng: 22.335727342444912, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Lesku",  },
{ lat: 49.4689312,  lng: 22.328476789999996, icon: eduIcon, type: "edu", title: "Urząd Miasta i Gminy Lesko",  },
{ lat: 54.10703845,  lng: 23.341704879679035, icon: eduIcon, type: "edu", title: "Zespół Szkolno - Przedszkolny w Sejnach",  },
{ lat: 54.106511350000005,  lng: 23.34968693259227, icon: eduIcon, type: "edu", title: "Urząd Miasta Sejny",  },
{ lat: 51.9901238,  lng: 15.2240919, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa im. K. Makuszyńskiego w Gronowie",  },
{ lat: 52.0078209,  lng: 15.1454924, icon: eduIcon, type: "edu", title: "Zespół Szkolno-Przedszkolny w Dąbiu",  },
{ lat: 52.01034155,  lng: 15.151270952487135, icon: eduIcon, type: "edu", title: "Urząd Gminy Dąbie",  },
{ lat: 50.71323475,  lng: 16.58570450990127, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa nr 1 im. Pieszyckiej Harcerskiej Organizacji Podziemnej",  },
{ lat: 50.712377,  lng: 16.5806611, icon: eduIcon, type: "edu", title: "Urząd Miasta i Gminy w Pieszycach",  },
{ lat: 52.17878225,  lng: 16.801496303526406, icon: eduIcon, type: "edu", title: "Szkoła Podstawowa w Pecnej",  },
{ lat: 51.7057448,  lng: 17.9861334, icon: sensoIcon, type: "senso", title: "Szkoła Podstawowa im. Polskich Noblistów w Nowych Skalmierzycach",  },

];

export default customers;
