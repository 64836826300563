import React from "react";

import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';

import "../assets/css/mapOfCustomers.css";
import customers from "./customers";
import LykeFooter from "./LykeFooter";


const LykeMapOfCustomers = () => {
  return (
      <div style={{paddingTop: "80px"}}>
        <MapContainer
          className="markercluster-map"
          center={[52.5, 19.3]}
          zoom={6}
          minZoom={6}
          maxZoom={18}
          scrollWheelZoom={true}
          zoomControl={false}
        >
          <ZoomControl position="bottomright"/>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
            {customers.map((elem) => (
              <Marker
                key={elem.title + elem.type + elem.lat}
                position={[elem.lat, elem.lng]}
                icon={elem.icon}
              >
                <Popup>
                  <p>{elem.title}</p>
                </Popup>
              </Marker>
            ))}
        </MapContainer>
        <LykeFooter />
      </div>
  );
};

export default LykeMapOfCustomers;
