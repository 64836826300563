import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col
} from "reactstrap";

const LykeFAQ = () => {

  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    };
  }
	return (
<>
<h2 className="faqTitle">NAJCZĘŚCIEJ ZADAWANE <span style={{color:"#024d9c", fontWeight:"bold"}}>PYTANIA</span></h2> 
<Container>
  <div className="faqCollapse">
              <div id="collapse" className="faqLyke" >
                <Row>
                  <Col md="12">
                    <div
                      aria-multiselectable={true}
                      className="card-collapse"
                      id="accordion"
                      role="tablist"
                    >
                      <Card className="card-plain">
                        <CardHeader id="headingOne" role="tab">
                          <a
                            aria-expanded={collapses.includes(1)}
                            data-toggle="collapse"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              changeCollapse(1);
                            }}
                          >
                            Czym są Bukiny?{" "}
                            <i className="now-ui-icons arrows-1_minimal-down"></i>
                          </a>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(1)}>
                          <CardBody>
                          To przedmioty wspierające rozwój Integracji Sensorycznej. My widzimy w nich jednak znacznie więcej zastosowań dobrych dla zdrowia. Nasze pomoce to komplet autorskich produktów, o których więcej dowiesz się w zakładce Pomoce SensoLyke.
                          </CardBody>
                        </Collapse>
                      </Card>
                      <Card className="card-plain">
                        <CardHeader id="headingTwo" role="tab">
                          <a
                            aria-expanded={collapses.includes(2)}
                            data-toggle="collapse"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              changeCollapse(2);
                            }}
                          >
                            Czy SensoLyke to tylko kurs?{" "}
                            <i className="now-ui-icons arrows-1_minimal-down"></i>
                          </a>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(2)}>
                          <CardBody>
                          SensoLyke to pakiet wiedzy i narzędzi. Oznacza to, że kurs to tylko połowa SensoLyke. Drugą częścią jest komplet pomocy sensorycznych, z pomocą których należy wykonywać ćwiczenia i zabawy przedstawione w kursie.
                          </CardBody>
                        </Collapse>
                      </Card>
                      <Card className="card-plain">
                        <CardHeader id="headingThree" role="tab">
                          <a
                            aria-expanded={collapses.includes(3)}
                            data-toggle="collapse"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              changeCollapse(3);
                            }}
                            
                          >
                            Co daje metoda SensoLyke?{" "}
                            <i className="now-ui-icons arrows-1_minimal-down"></i>
                          </a>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(3)}>
                          <CardBody>
                          Wiedzę i narzędzia. Dowiesz się jak zapobiegać problemom behawioralnym u dzieci, jak wspierać rozwój ich inteligencji emocjonalnej, jak dbać o ich zdrowie i higienę psychiczną, a także w jaki sposób wspomagać prawidłowe kształtowanie integracji sensorycznej. Wszystko w teorii, praktyce i z narzędziami do wykonania zabaw.
                          </CardBody>
                        </Collapse>
                      </Card>
                      <Card className="card-plain">
                        <CardHeader id="headingThree" role="tab">
                          <a
                            aria-expanded={collapses.includes(4)}
                            data-toggle="collapse"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              changeCollapse(4);
                            }}
                          >
                            Dla kogo jest SensoLyke?{" "}
                            <i className="now-ui-icons arrows-1_minimal-down"></i>
                          </a>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(4)}>
                          <CardBody>
                          Dedykowana jest dzieciom w wieku przedszkolnym i wczesnoszkolnym. To do ich możliwości i potrzeb dostosowane są scenariusze zajęć. Natomiast poznanie i zrozumienie założeń metody pozwala na wykorzystanie jej także w innych grupach wiekowych.
                          </CardBody>
                        </Collapse>
                      </Card>
                      <Card className="card-plain">
                        <CardHeader id="headingThree" role="tab">
                          <a
                            aria-expanded={collapses.includes(5)}
                            data-toggle="collapse"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              changeCollapse(5);
                            }}
                          >
                            Z czego stworzone są Bukiny?{" "}
                            <i className="now-ui-icons arrows-1_minimal-down"></i>
                          </a>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(5)}>
                          <CardBody>
                          Wypełnione są łuską gryki. Dzięki temu są antyalergiczne, naturalne i bardzo przyjemnie szumiące. Ubrane są w miękki materiał, który jest elastyczny i przypomina mieszankę polaru z welurem.
                          </CardBody>
                        </Collapse>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </div>

              </div>
              <div className="faqHands"> <img alt="dłonie wskazujace na pytania" src={require("assets/img/rece.png")}></img>
</div>

  </Container>
</>
      

	)
    }
export default LykeFAQ;